export const collections = {
  production: {
    schoolInventories: "school_inventories",
    schoolInventoryEntries: "school_inventory_entries",
    books: "books",
    users: "users",
  },
  localhost: {
    schoolInventories: "school_inventories_localhost",
    schoolInventoryEntries: "school_inventory_entries_localhost",
    books: "books_localhost",
    users: "users_localhost",
  },
};
