import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Dashboard } from "../Dashboard";
import LoadingBackdrop from "../../components/LoadingBackdrop.tsx";
import { MasterInventoryContext } from "../../App";
import { Typography } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import SaveIcon from "@mui/icons-material/Save";

import PropTypes from "prop-types";
import { updateBook } from "../../state/firebase/books.ts";
import ScanForm from "./ScanForm";
import BookDisplay from "./BookDisplay";

export default function LocationsScreen() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const masterInventory = React.useContext(MasterInventoryContext);

  const [loading, setLoading] = React.useState(false);
  const [book, setBook] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);

  // These fields control the change location dialog
  const [changeLocationDialogOpen, setChangeLocationDialogOpen] =
    React.useState(false);
  const [changeLocationBook, setChangeLocationBook] = React.useState(null);
  const [changeLocationLocation, setChangeLocationLocation] =
    React.useState(null);

  const handleScan = (data) => {
    console.log("Scanning ISBN: " + data.isbn);
    console.log("Scanning location: ", data.location);

    setSuccessMessage(null);
    setErrorMessage(null);

    // Find that isbn in the master inventory
    let book = masterInventory.searchByISBN(data.isbn);
    if (!book) {
      enqueueSnackbar(t("errors.isbn-not-found"), {
        variant: "error",
      });
      setBook(null);

      // @todo dialog to add book?
      setErrorMessage(t("errors.isbn-not-found"));

      return;
    }

    // Print new and old codes
    console.log("Book location: " + book.primary_location);
    console.log("New location: " + data.location.code());

    // If the book is in no location, save away
    if (!book.primary_location || book.primary_location === "") {
      saveLocation(book, data.location);
      return;
    }

    // If the book is already in that location, do nothing
    if (book.primary_location === data.location.code()) {
      setSuccessMessage(
        t("alerts.book-location-same", {
          location: data.location.name(),
        })
      );
      return;
    }

    // Must be trying to move it. Confirm first.
    setChangeLocationDialogOpen(true);
    setChangeLocationBook(book);
    setChangeLocationLocation(data.location);
  };

  const saveLocation = (book, newLocation) => {
    // Set location on book
    book.primary_location = newLocation.code();

    // Update book entry
    setLoading(true);
    updateBook(book)
      .then(() => {
        console.log("Book updated: " + book.id);
        setBook(book);
        setSuccessMessage(
          t("alerts.book-location-saved", {
            location: newLocation.name(),
          })
        );
      })
      .catch((error) => {
        console.error("Error updating book: ", error);
        setErrorMessage(t("errors.book-save"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dashboard title={t("titles.locations")}>
      <ChangeLocationDialog
        open={changeLocationDialogOpen}
        onClose={() => {
          // Clear dialog fields
          setChangeLocationDialogOpen(false);
          setChangeLocationBook(null);
          setChangeLocationLocation(null);
        }}
        onConfirm={() => {
          // Save location
          saveLocation(changeLocationBook, changeLocationLocation);
        }}
        book={changeLocationBook}
      />

      <Grow in={true}>
        <Grid container spacing={3}>
          {/* Left column */}
          <Grid item xs={12} md={8} lg={9}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LoadingBackdrop
                loading={masterInventory.loading || loading}
                timer={5}
              />
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  marginBottom: 2,
                }}
              >
                {t("prompts.scan-book-and-location-code")}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ScanForm onDataReady={handleScan} />
                </Grid>

                <Grid item xs={12}>
                  <Grow in={successMessage !== null}>
                    <Alert severity="success">
                      <AlertTitle>{t("labels.success")}</AlertTitle>
                      {successMessage}
                    </Alert>
                  </Grow>
                  <Grow in={errorMessage !== null}>
                    <Alert severity="error">
                      <AlertTitle>{t("errors.error")}</AlertTitle>
                      {errorMessage}
                    </Alert>
                  </Grow>
                  {book && <BookDisplay book={book} />}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grow>
    </Dashboard>
  );
}

ScanForm.propTypes = {
  onDataReady: PropTypes.func.isRequired,
};

// Dialog to confirm if a book should have its location changed
const ChangeLocationDialog = ({ open, onClose, onConfirm, book }) => {
  const { t } = useTranslation();

  const oldLocation = book?.getPrimaryLocation()?.name() || "";

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("titles.confirm-change-location")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("prompts.confirm-change-location", {
            location: oldLocation,
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          startIcon={<SaveIcon />}
          onClick={onClose}
          color="error"
        >
          {t("buttons.cancel")}
        </Button>
        <Button
          variant="contained"
          startIcon={<CancelIcon />}
          onClick={() => {
            onConfirm();
            onClose();
          }}
          color="success"
        >
          {t("buttons.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
