import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  doc,
  updateDoc,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "firebase/firestore";
import { collections } from "../../utils/constants.ts";
import { getEnvironment } from "../../utils/environment.ts";

/*
    Only up to date @docs I can find...
    https://firebase.google.com/docs/firestore/quickstart

    Auth demo I copied a lot of
    https://github.com/atharvadeosthale/firebase-auth-article

*/

const firebaseConfig = {
  apiKey: "AIzaSyCI5OKm56wlk-rL7qI5gwSFNK885YA_w0Y",
  authDomain: "invenstory-react-dev.firebaseapp.com",
  projectId: "invenstory-react-dev",
  storageBucket: "invenstory-react-dev.appspot.com",
  messagingSenderId: "331812088897",
  appId: "1:331812088897:web:a4f4d5b41f4a2be87deac0",
};

// Initialize firebase instances
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
  }),
});

const googleProvider = new GoogleAuthProvider();

const usersCollectionsName = () => {
  switch (getEnvironment()) {
    case "localhost":
      return collections.localhost.users;
    case "production":
      return collections.production.users;
    default:
      throw new Error("Unknown environment: " + getEnvironment());
  }
};

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;

    // Update user record
    const docRef = doc(db, usersCollectionsName() + "/" + user.uid);
    await updateDoc(docRef, {
      name: user.displayName,
      authProvider: "google",
      email: user.email,
      uid: user.uid,
      photoURL: user.photoURL,
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const logout = () => {
  signOut(auth);
};

export { auth, db, signInWithGoogle, logout };
