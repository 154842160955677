import React from "react";
import PropTypes from "prop-types";
// import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
// import Skeleton from "@mui/material/Skeleton";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import BookEditDialog from "../../components/BookEditDialog";
import { TableHeaderCell } from "./TableHeaderCell";

import { Scrollbar } from "../../components/scrollbar";
import { t } from "i18next";
import { SkeletonRow } from "./SkeletonRow";
import { useFieldOptions } from "../../hooks/useFieldOptions";
import BookDeleteDialog from "../../components/BookDeleteDialog";

export const BookListTable = ({
  books,
  booksCount,
  onChangeSort,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
  totalBooksQuantity,
  loadingBooks,
  handleUpdateFilter,
  sort,
  ...rest
}) => {
  const skeletonsToRender = loadingBooks && rowsPerPage - books.length;
  const { bookLanguages, bookTypes, bookLevels } = useFieldOptions();
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [editDialogBook, setEditDialogBook] = React.useState(null);
  const [sortBy, sortDirection] = sort.split("|");

  const createSortHandler = (property) => () => {
    onChangeSort(property);
  };

  return (
    <div {...rest}>
      {editDialogOpen && (
        <BookEditDialog
          isOpen={editDialogOpen}
          book={editDialogBook}
          onClose={() => {
            setEditDialogOpen(false);
          }}
        />
      )}
      {deleteDialogOpen && (
        <BookDeleteDialog
          isOpen={deleteDialogOpen}
          book={editDialogBook}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
        />
      )}
      <Scrollbar>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableHeaderCell
                sortEnabled
                sortActive={sortBy === "title"}
                sortDirection={sortDirection}
                sortOnClick={createSortHandler("title")}
              >
                {t("labels.book")}
              </TableHeaderCell>
              <TableHeaderCell
                sortEnabled
                sortDirection={sortDirection}
                align="right"
                sortActive={sortBy === "quantity"}
                sortOnClick={createSortHandler("quantity")}
              >
                {t("labels.quantity")} ({totalBooksQuantity})
              </TableHeaderCell>
              <TableHeaderCell
                multiFilter
                updateFilter={handleUpdateFilter("level")}
                filterOptions={[...bookLevels, { label: "None", value: "" }]}
              >
                {t("labels.reading-level")}
              </TableHeaderCell>
              <TableHeaderCell
                sortDirection={sortDirection}
                sortActive={sortBy === "primary_location"}
                sortOnClick={createSortHandler("primary_location")}
                sortEnabled
              >
                {t("titles.location")}
              </TableHeaderCell>
              <TableHeaderCell
                updateFilter={handleUpdateFilter("language")}
                filterOptions={[...bookLanguages, { label: "None", value: "" }]}
              >
                {t("labels.language")}
              </TableHeaderCell>
              <TableHeaderCell
                multiFilter
                updateFilter={handleUpdateFilter("type")}
                filterOptions={[...bookTypes, { label: "None", value: "" }]}
              >
                {t("labels.type")}
              </TableHeaderCell>
              <TableHeaderCell align="right"></TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {books.map((book) => {
              return (
                <TableRow hover key={book.id}>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {/* {book.thumbnail ? (
                        <Avatar
                          src={book.thumbnail}
                          sx={{
                            height: 42,
                            minHeight: 42,
                            minWidth: 42,
                            width: 42,
                          }}
                        />
                      ) : (
                        <Skeleton
                          animation={false}
                          variant="circular"
                          width={42}
                          height={42}
                          sx={{
                            height: 42,
                            minHeight: 42,
                            minWidth: 42,
                            width: 42,
                          }}
                        />
                      )} */}
                      <Box>
                        <Link
                          color="inherit"
                          variant="subtitle2"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            // open the edit book modal
                            setEditDialogOpen(true);
                            setEditDialogBook(book);
                          }}
                        >
                          {book.title}
                        </Link>
                        <Typography color="textSecondary" variant="body2">
                          {book.author}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      style={{ textAlign: "right" }}
                    >
                      {book.quantity}
                    </Typography>
                  </TableCell>
                  <TableCell>{t(book.level || "-")}</TableCell>
                  <TableCell>
                    {book.getPrimaryLocation().name() || "-"}
                  </TableCell>
                  <TableCell>{t(book.language || "-")}</TableCell>
                  <TableCell>{book.type || "-"}</TableCell>
                  <TableCell align="right" style={{ minWidth: "100px" }}>
                    <IconButton
                      onClick={() => {
                        setEditDialogOpen(true);
                        setEditDialogBook(book);
                      }}
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={() => {
                        // Confirm deletion of book
                        setDeleteDialogOpen(true);
                        setEditDialogBook(book);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
            {!!skeletonsToRender &&
              [...Array(skeletonsToRender).keys()].map((_, i) => (
                <SkeletonRow key={i} />
              ))}
          </TableBody>
        </Table>
      </Scrollbar>
      <TablePagination
        component="div"
        count={booksCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[2, 3, 4, 10, 25, 50]}
      />
    </div>
  );
};

BookListTable.propTypes = {
  books: PropTypes.array.isRequired,
  booksCount: PropTypes.number.isRequired,
  loadingBooks: PropTypes.bool.isRequired,
  onChangeSort: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  sort: PropTypes.string.isRequired,
};
