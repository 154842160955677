import { db } from "./firebase";
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { getEnvironment } from "../../utils/environment.ts";
import { collections } from "../../utils/constants.ts";

const schoolInventoryCollection = () => {
  switch (getEnvironment()) {
    case "localhost":
      return collection(db, collections.localhost.schoolInventories);
    case "production":
      return collection(db, collections.production.schoolInventories);
    default:
      throw new Error("Unknown environment: " + getEnvironment());
  }
};

const schoolInventoryCollectionName = () => {
  switch (getEnvironment()) {
    case "localhost":
      return collections.localhost.schoolInventories;
    case "production":
      return collections.production.schoolInventories;
    default:
      throw new Error("Unknown environment: " + getEnvironment());
  }
};

export function useSchoolInventory() {
  return useCollection(schoolInventoryCollection(), {});
}

// Function to add a new book to firebase
// Returns a promise that resolves to the new book
export const addSchoolInventory = (schoolInventory) => {
  const now = new Date();
  const newSchoolInventory = {
    ...schoolInventory,
    createdAt: now,
    updatedAt: now,
  };

  return addDoc(schoolInventoryCollection(), newSchoolInventory);
};

// Function to update a book in firebase
// Returns a promise that resolves to the updated book
export const updateSchoolInventory = (schoolInventory) => {
  const now = new Date();
  const updatedSchoolInventory = {
    ...schoolInventory,
    updatedAt: now,
  };

  const docRef = doc(
    db,
    schoolInventoryCollectionName() + "/" + schoolInventory.id
  );
  return updateDoc(docRef, updatedSchoolInventory);
};

// Function to delete a book from firebase
// Returns a promise that resolves to the deleted book
export const deleteSchoolInventory = (schoolInventory) => {
  const docRef = doc(
    db,
    schoolInventoryCollectionName() + "/" + schoolInventory.id
  );
  return deleteDoc(docRef);
};
