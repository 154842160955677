import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import OpenIcon from "@mui/icons-material/OpenInNew";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import * as React from "react";
import { useTranslation } from "react-i18next";
import SchoolInventory from "../../state/SchoolInventory";
import LoadingBackdrop from "../../components/LoadingBackdrop.tsx";
import Title from "../../components/Title";
import SchoolInventoryMetadataDialog from "./MetadataDialog";
import { Dashboard } from "../Dashboard";
import { useNavigate } from "react-router-dom";
import { useSchoolInventory } from "../../state/firebase/schoolInventories";

export default function InventoriesScreen() {
  const { t } = useTranslation();

  let navigate = useNavigate();
  const openInventory = (inventoryID) =>
    navigate("/inventories/" + inventoryID);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogMode, setDialogMode] = React.useState("");
  const [dialogSchoolInventory, setDialogSchoolInventory] =
    React.useState(false);

  const [schoolInventoriesDocs, loading, error] = useSchoolInventory();
  const [schoolInventories, setSchoolInventories] = React.useState([]);

  // When the docset gets a new snapshot, update the school inventories var
  React.useEffect(() => {
    if (!schoolInventoriesDocs) return;

    let _si = schoolInventoriesDocs.docs.map((doc) => {
      let si = new SchoolInventory(doc.data());
      si.id = doc.id;

      // Add it to the list
      return si;
    });

    setSchoolInventories(_si);
  }, [schoolInventoriesDocs]);

  return (
    <Dashboard
      title={t("titles.school-inventories")}
      button={
        <Button
          variant="outlined"
          color="primary"
          startIcon={<OpenIcon />}
          style={{ width: "300px" }}
          onClick={() => {
            setDialogMode("create");
            setDialogSchoolInventory(new SchoolInventory());
            setDialogOpen(true);
          }}
        >
          {t("buttons.create-new")}
        </Button>
      }
    >
      <Grow in={true}>
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LoadingBackdrop loading={loading} timer={10} />
              <Collapse in={error ? true : false}>
                <Alert severity="error">
                  <AlertTitle>{t("errors.error")}</AlertTitle>
                  {error ? error : ""}
                </Alert>
              </Collapse>
              {dialogOpen && (
                <SchoolInventoryMetadataDialog
                  schoolInventory={dialogSchoolInventory}
                  mode={dialogMode}
                  open={dialogOpen}
                  done={() => {
                    setDialogOpen(false);
                    setDialogSchoolInventory(null);
                  }}
                />
              )}
              <InventoryTable
                schoolInventories={schoolInventories}
                openInventory={(id) => {
                  // let inventory = schoolInventories.find(
                  //   (si) => si.id === id
                  // );
                  openInventory(id);
                }}
                editInventory={(id) => {
                  let inventory = schoolInventories.find((si) => si.id === id);
                  setDialogMode("edit");
                  setDialogSchoolInventory(inventory);
                  setDialogOpen(true);
                }}
                deleteInventory={(id) => {
                  // Get the inventory to delete
                  let inventory = schoolInventories.find((si) => si.id === id);

                  // Confirm deletion
                  setDialogMode("delete");
                  setDialogSchoolInventory(inventory);
                  setDialogOpen(true);
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grow>
    </Dashboard>
  );
}

function InventoryTable(props) {
  const { t, i18n } = useTranslation();

  // When the school inventories prop changes, sort it by updatedAt, with newest first
  let inventories = props.schoolInventories;
  if (!inventories) return <></>;
  inventories.sort((a, b) => {
    return b.updatedAt - a.updatedAt;
  });

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t("labels.name")}</TableCell>
          <TableCell>{t("labels.type")}</TableCell>
          <TableCell>{t("labels.last-modified")}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {inventories.map((row, i) => (
          <TableRow key={i}>
            <TableCell>
              <Title>{row.name}</Title>
            </TableCell>
            <TableCell>
              {row.type === "school" ? t("school-type") : t("receipt-type")}
            </TableCell>
            <TableCell>{row.updatedAt.toLocaleString(i18n.language)}</TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <Button
                startIcon={<OpenIcon />}
                variant="contained"
                onClick={() => {
                  props.openInventory(row.id);
                }}
              >
                {t("buttons.open")}
              </Button>
              &nbsp;
              <Button
                startIcon={<EditIcon />}
                variant="contained"
                color="secondary"
                onClick={() => {
                  props.editInventory(row.id);
                }}
              >
                {t("buttons.edit")}
              </Button>
              &nbsp;
              <Button
                startIcon={<DeleteIcon />}
                variant="contained"
                color="error"
                onClick={() => {
                  props.deleteInventory(row.id);
                }}
              >
                {t("buttons.delete")}
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
