import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grow from "@mui/material/Grow";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";

import PropTypes from "prop-types";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import Book from "../state/Book";
import { getSchoolInventoryEntryByBookID } from "../state/firebase/schoolInventoryEntries";
import { CircularProgress } from "@mui/material";
import { deleteBook } from "../state/firebase/books.ts";
import { useSnackbar } from "notistack";

BookDeleteDialog.propTypes = {
  book: PropTypes.instanceOf(Book).isRequired,
  isOpen: PropTypes.bool.isRequired, // the outside world controls if we're open or not
  onClose: PropTypes.func.isRequired, // When we're done (cancel button, save is complete, ...) let the world know so they can close us
};

export default function BookDeleteDialog(props) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [removing, setRemoving] = React.useState(false);

  const { t } = useTranslation();

  const handleDeleteBook = async () => {
    setRemoving(true);
    // NOTE: Before deleting, check if the book is used in any school inventories
    const inSchoolInventory = await getSchoolInventoryEntryByBookID(
      props.book.id
    );
    if (inSchoolInventory) {
      enqueueSnackbar(t("errors.book-in-inventory"), { variant: "error" });
      setRemoving(false);
      return;
    }

    try {
      await deleteBook(props.book);
      setRemoving(false);
      enqueueSnackbar(t("alerts.school-inventory-entry-deleted"), {
        variant: "success",
      });
      props.onClose();
      return;
    } catch (err) {
      enqueueSnackbar(t("errors.book-delete-failed"), { variant: "error" });
    }
    setRemoving(false);
  };

  return (
    <Dialog
      open={props.isOpen}
      TransitionComponent={Transition}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      maxWidth={"lg"}
      fullScreen={fullScreen}
    >
      <DialogTitle id="alert-dialog-title">
        {t("titles.delete-book")}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {t("questions.confirm-delete-book")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">
              {t("labels.title")}: {props.book.title}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">
              {t("labels.author")}: {props.book.author}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteBook}
              disabled={removing}
            >
              {removing ? <CircularProgress size={24} /> : t("buttons.delete")}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} startIcon={<CancelIcon />}>
          {t("buttons.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      timeout={1000}
      ref={ref}
      {...props}
      {...{ timeout: 600 }} // why do I have to use this weird syntax?
    />
  );
});
