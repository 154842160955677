import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import * as React from "react";
import { useTranslation } from "react-i18next";
import CreateUpdateBookForm from "../components/CreateUpdateBookForm.jsx";
import { Dashboard } from "./Dashboard.jsx";
import { IsbnBookSearch } from "../components/IsbnBookSearch.tsx";

export default function AddByISBN() {
  const { t } = useTranslation();

  const [book, setBook] = React.useState(null);

  const clearBook = () => {
    setBook(null);
  };

  return (
    <Dashboard>
      <Grow in={true}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {!book ? (
                <IsbnBookSearch setBook={setBook} />
              ) : (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      startIcon={<ChevronLeftIcon />}
                      onClick={() => {
                        clearBook();
                      }}
                    >
                      {t("buttons.return")}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <CreateUpdateBookForm
                      defaultBook={book}
                      onComplete={clearBook}
                    />
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grow>
    </Dashboard>
  );
}
