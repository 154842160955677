import CellWifiIcon from "@mui/icons-material/CellWifi";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import SignalCellularConnectedNoInternet0BarIcon from "@mui/icons-material/SignalCellularConnectedNoInternet0Bar";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import { enUS, esES } from "@mui/material/locale";
import Paper from "@mui/material/Paper";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useTranslation } from "react-i18next";
import MainMenu from "../components/DrawerMenu";
import { logout } from "../state/firebase/firebase";
import preval from "preval.macro";
import { getEnvironment } from "../utils/environment.ts";

function Copyright(props) {
  const { t } = useTranslation();

  const dateTimeStamp = preval`module.exports = new Date().toISOString();`;

  return (
    <>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {t("copyright") + " © "}
        <Link color="inherit" href="https://adambuilds.com">
          Adam Brakhane
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        Build: {dateTimeStamp}
      </Typography>
    </>
  );
}

const drawerWidth = 210;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

function Dashboard(props) {
  const { i18n } = useTranslation();

  const [mdTheme, setMdTheme] = React.useState(createTheme({}, enUS));

  // When language changes, update the theme
  React.useEffect(() => {
    const muiLocale = i18n.language === "en" ? enUS : esES;
    setMdTheme(createTheme({}, muiLocale));
  }, [i18n.language]);

  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    // app bar starts closed if the screen is smaller than 400 px wide
    if (window.innerWidth < 700) {
      setOpen(false);
    }
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <img
                src="/img/icon-book.png"
                alt="invenstory icon"
                style={{
                  marginRight: "8px",
                  verticalAlign: "middle",
                  height: "32px",
                }}
              />
              Inven<sup>Story</sup>
            </Typography>
            {getEnvironment() === "localhost" && (
              <Paper
                sx={{
                  display: "inline-block",
                  marginLeft: "auto",
                  marginRight: "50px",
                  padding: "4px 8px",
                  borderRadius: "4px",
                  backgroundColor: "red",
                  color: "white",
                }}
              >
                LOCALHOST
              </Paper>
            )}

            <IconConnectedToInternet />

            <Button
              color="inherit"
              onClick={() => {
                console.log(i18n);
                console.log(i18n.language);

                switch (i18n.language) {
                  case "en":
                    i18n.changeLanguage("es");
                    break;
                  case "es":
                    i18n.changeLanguage("en");
                    break;
                  default:
                    i18n.changeLanguage("en");
                }
              }}
            >
              {i18n.language === "en" ? "🇺🇸" : "🇭🇳"}
            </Button>

            {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <IconButton
              color="inherit"
              onClick={() => {
                logout();
              }}
            >
              <LogoutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <MainMenu></MainMenu>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                {props.title && (
                  <Typography variant="h4" component="h3">
                    {props.title}
                  </Typography>
                )}
                {props?.subtitle && (
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: "1.2rem",
                    }}
                  >
                    {props.subtitle}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                {props?.button}
              </Grid>
            </Grid>
            {props.children}
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

// export default withNamespaces()(Dashboard);

function DashboardContent() {
  const { t } = useTranslation();

  return (
    <Dashboard>
      <Grow in={true}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={9}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="h3">
                {t("welcome-to")} Inven<sup>Story</sup>
              </Typography>

              <Typography
                component="h1"
                variant="overline"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                {/* {t("labels.logged-in-as")} <DisplayName /> */}
                NO DISPLAY NAME
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 240,
              }}
            ></Paper>
          </Grid>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              {/* <Orders /> */}
              {/* <MasterInventory2 /> */}
            </Paper>
          </Grid>
        </Grid>
      </Grow>
    </Dashboard>
  );
}

function IconConnectedToInternet() {
  const [isOnline, setIsOnline] = React.useState(true);
  const [offlineSnackbarKey, setOfflineSnackbarKey] = React.useState(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { t } = useTranslation();

  // Monitor internet connection status and update isOnline accordingly
  React.useEffect(() => {
    window.addEventListener("online", function () {
      setIsOnline(true);
    });
    window.addEventListener("offline", function () {
      setIsOnline(false);
    });

    // Set on page load
    setIsOnline(window.navigator.onLine);
  }, []);

  React.useEffect(() => {
    if (isOnline) {
      closeSnackbar(offlineSnackbarKey);
    } else {
      const key = enqueueSnackbar(t("status.offline") + "!", {
        variant: "error",
        persist: true,
      });
      setOfflineSnackbarKey(key);
    }
  }, [isOnline]);

  return (
    <>
      <Button color="info" aria-label="connectivity" variant="contained">
        {isOnline ? (
          <>
            {t("status.online")} <CellWifiIcon color="white" />
          </>
        ) : (
          <>
            {t("status.offline")}
            <SignalCellularConnectedNoInternet0BarIcon color="error" />
          </>
        )}
      </Button>
    </>
  );
}

// Center the loading indicator in the middle of the screen
function Loading() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "60vh",
      }}
    >
      {/* <CircularProgress size={200} thickness={2} /> */}
      <div className="bg applicationLoader shadow"></div>
      <div className="bookshelf_wrapper applicationLoader">
        <ul className="books_list">
          <li className="book_item first"></li>
          <li className="book_item second"></li>
          <li className="book_item third"></li>
          <li className="book_item fourth"></li>
          <li className="book_item fifth"></li>
          <li className="book_item sixth"></li>
        </ul>
        <div className="shelf"></div>
        <div className="loading">
          <span>{t("loading")}</span>...
        </div>
      </div>
    </Box>
  );
}

export { Dashboard, DashboardContent, Loading, IconConnectedToInternet };
