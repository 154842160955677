import { Timestamp } from "firebase/firestore";
export default class SchoolInventory {
  constructor(obj) {
    if (typeof obj == "undefined") {
      obj = {};
    }

    const now = new Date();

    // Set up defaults for id, name, and type
    this.id = "id" in obj ? obj.id : "";
    this.name = "name" in obj ? obj.name : "";
    this.type = "type" in obj ? obj.type : "";

    this.updatedAt = "updatedAt" in obj ? obj.updatedAt : now;
    this.createdAt = "createdAt" in obj ? obj.createdAt : now;

    // If the dates are firebase dates, convert them to javascript dates
    if (this.updatedAt instanceof Timestamp) {
      this.updatedAt = this.updatedAt.toDate();
    }
    if (this.createdAt instanceof Timestamp) {
      this.createdAt = this.createdAt.toDate();
    }
  }

  data() {
    return {
      id: this.id,

      name: this.name,
      type: this.type,

      updatedAt: this.updatedAt,
      createdAt: this.createdAt,
    };
  }

  log() {
    console.log("School Inventory: " + this.id);
    console.log(this.data());
  }
}
