import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useTranslation } from "react-i18next";

const BookDisplay = ({ book }) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("labels.isbn")}</TableCell>
            <TableCell>{t("labels.title")}</TableCell>
            <TableCell>{t("labels.author")}</TableCell>
            <TableCell>{t("labels.reading-level")}</TableCell>
            <TableCell>{t("labels.primary-location")}</TableCell>
            <TableCell>{t("labels.quantity")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{book.isbn}</TableCell>
            <TableCell>{book.title}</TableCell>
            <TableCell>{book.author}</TableCell>
            <TableCell>{t(book.level)}</TableCell>
            <TableCell>{book.getPrimaryLocation().name()}</TableCell>
            <TableCell>{book.quantity}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BookDisplay;
