import React from "react";
import { useContext } from "react";
import { MasterInventoryContext } from "../App";
import { Dashboard } from "./Dashboard";
import { useAllSchoolInventoryEntries } from "../state/firebase/schoolInventoryEntries";
import {
  Button,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Paper,
} from "@mui/material";
import { deleteBook } from "../state/firebase/books.ts";
import { useSnackbar } from "notistack";
import { updateDoc } from "firebase/firestore";

export default function DuplicateBooksScreen() {
  const { enqueueSnackbar } = useSnackbar();

  // Get entire master inventory
  const masterInventory = useContext(MasterInventoryContext);

  // Get all inventory entries
  const [inventoryEntries] = useAllSchoolInventoryEntries();

  const [simplifiedEntries, setSimplifiedEntries] = React.useState([]);

  const [duplicateISBNs, setDuplicateISBNs] = React.useState([]);

  const findMatches = () => {
    // Print out inventory entries
    const simplifiedEntries = inventoryEntries.docs.map((doc) => {
      const entry = doc.data();

      return {
        listID: entry.listID,
        bookID: entry.bookID,
      };
    });
    setSimplifiedEntries(simplifiedEntries);

    // Copy current state
    let seenISBNs = [];
    let duplicateISBNs = [];

    // Iterate through master inventory books
    for (let i = 0; i < masterInventory.books.length; i++) {
      // Mark this book as seen
      const book = masterInventory.books[i];
      if (seenISBNs.includes(book.isbn)) {
        duplicateISBNs = [...duplicateISBNs, book.isbn];
        continue;
      } else {
        seenISBNs = [...seenISBNs, book.isbn];
      }
    }

    setDuplicateISBNs(duplicateISBNs);
  };

  const [entries, setEntries] = React.useState([]);
  //   When duplicate isbns are set, create the entries
  React.useEffect(() => {
    if (duplicateISBNs.length > 0) {
      let entries = [];
      duplicateISBNs.forEach((isbn) => {
        // Get all books from the master inventory that match that isbn
        for (let i = 0; i < masterInventory.books.length; i++) {
          const book = masterInventory.books[i];
          if (book.isbn === isbn) {
            // Is this book used in a school inventory?
            let inUse = false;
            let inUseWhere = "";
            // Does this book ID exist in an inventory entry
            simplifiedEntries.filter((entry) => {
              if (entry.bookID === book.id) {
                inUse = true;
                inUseWhere += entry.listID + " ";
              }

              return true;
            });

            entries = [
              ...entries,
              {
                book: book,
                isbn: isbn,
                inUse: inUse,
                inUseWhere: inUseWhere,
                count: 1,
                otherIDs: "",
              },
            ];
          }
        }
      });

      // Identify other duplicates
      entries = entries.map((entry) => {
        // Identify the other duplicates
        let otherIDs = entries.filter((e) => {
          // console.log(
          //   `${e.isbn} === ${entry.isbn} && ${e.book.id} !== ${entry.book.id}`
          // );
          return e.isbn === entry.isbn && e.book.id !== entry.book.id;
        });

        // Just get IDs from the other duplicates
        otherIDs = otherIDs.map((e) => {
          return e.book.id;
        });

        // Add the other IDs to the entry
        entry.otherIDs = otherIDs;

        return entry;
      });

      // Add counts
      entries = entries.map((entry) => {
        entry.count = entries.filter((e) => e.isbn === entry.isbn).length;

        return entry;
      });

      setEntries(entries);
    }
  }, [duplicateISBNs, masterInventory.books, simplifiedEntries]);

  return (
    <Dashboard>
      <Paper
        style={{
          padding: "1rem",
          margin: "1rem",
        }}
      >
        <Button
          onClick={() => {
            findMatches();
          }}
        >
          Find Duplicates
        </Button>
        <h1>Duplicate ISBNs</h1>
        {/* List duplicate ISBNs */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ISBN</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Author</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>In Use</TableCell>
              <TableCell># Duplicates</TableCell>
              <TableCell>ID</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map((entry) => {
              return (
                <TableRow key={entry.book.id}>
                  <TableCell>{entry.isbn}</TableCell>
                  <TableCell>{entry.book.title}</TableCell>
                  <TableCell>{entry.book.author}</TableCell>
                  <TableCell>{entry.book.quantity}</TableCell>
                  <TableCell>
                    {entry.inUse ? "Yes (" + entry.inUseWhere + ")" : "No"}
                  </TableCell>
                  <TableCell>{entry.count}</TableCell>
                  <TableCell>{entry.book.id}</TableCell>
                  <TableCell>
                    {entry.inUse ? (
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => {
                          console.log(entry.otherIDs);

                          // replace this other book with this book in list entries
                          // Find all docs that match the other IDs in the school inventory entries
                          inventoryEntries.docs.forEach((doc) => {
                            if (entry.otherIDs.includes(doc.data().bookID)) {
                              // Replace the bookID with the bookID of this book
                              const msg =
                                "Replacing " +
                                doc.data().bookID +
                                " with " +
                                entry.book.id +
                                " in " +
                                doc.data().listID;

                              console.log(msg);

                              updateDoc(doc.ref, {
                                bookID: entry.book.id,
                              })
                                .then(() => {
                                  console.log("Success: " + msg);
                                })
                                .catch((error) => {
                                  console.log("Error: " + msg);
                                  console.log(error);
                                });
                            }
                          });
                        }}
                      >
                        Keep this book
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          console.log("Delete");

                          deleteBook(entry.book)
                            .then(() => {
                              console.log("Book deleted: ", entry.book.id);
                              enqueueSnackbar(
                                "Book deleted: " + entry.book.id,
                                {
                                  variant: "success",
                                }
                              );
                            })
                            .catch((err) => {
                              console.error("Book delete error: ", err);

                              enqueueSnackbar("Book delete error: " + err, {
                                variant: "error",
                              });
                            });
                        }}
                      >
                        Delete
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </Dashboard>
  );
}
