import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { useContext } from "react";
import { MasterInventoryContext } from "../../App";
import { Dashboard } from "../../screens/Dashboard";
import { useSnackbar } from "notistack";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import CancelIcon from "@mui/icons-material/Cancel";
import Title from "../../components/Title";
import { doc, writeBatch } from "firebase/firestore";
import { db } from "../../state/firebase/firebase";
import { booksCollectionName } from "../../state/firebase/books.ts";

export default function ZeroOutQuantities() {
  const { enqueueSnackbar } = useSnackbar();
  // Get entire master inventory
  const masterInventory = useContext(MasterInventoryContext);
  const [books, setBooks] = React.useState([]);
  React.useEffect(() => {
    if (!masterInventory) return;
    if (!masterInventory.books) return;

    setBooks(masterInventory.books);
  }, [masterInventory]);

  // Display the sum quantity
  const [booksQuantity, setBooksQuantity] = React.useState(0);
  React.useEffect(() => {
    // When books changes count the quantity of all books
    let quantity = 0;
    books.forEach((book) => {
      const bookQuantity = parseInt(book.quantity);
      quantity += isNaN(bookQuantity) ? 0 : bookQuantity;
    });
    setBooksQuantity(quantity);
  }, [books]);

  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const zeroOutQuantities = () => {
    // Group books into batches of 500 and process each
    const bookBatches = [];
    let bookBatch = [];
    books.forEach((book) => {
      bookBatch.push(book);
      if (bookBatch.length === 500) {
        bookBatches.push(bookBatch);
        bookBatch = [];
      }
    });

    let msg = "Gathered " + bookBatches.length + " batches of 500 books";

    if (bookBatch.length > 0) {
      msg += " and 1 batch of " + bookBatch.length + " books";
      bookBatches.push(bookBatch);
    }

    console.info(msg);

    // Batch update
    bookBatches.forEach((bookBatch, index) => {
      const batch = writeBatch(db);

      console.info("Created batch " + index);

      // Update each book in batch
      bookBatch.forEach((book) => {
        const ref = doc(db, booksCollectionName() + "/" + book.id);
        batch.update(ref, { quantity: 0 });
      });

      // Commit batch syncronously
      batch
        .commit()
        .then(() => {
          console.log("Batch " + index + " committed successfully");
          enqueueSnackbar("Zeroed out quantities", { variant: "success" });
        })
        .catch((error) => {
          console.error(
            "Batch " + index + ": Error zeroing out quantities",
            error
          );
          enqueueSnackbar("Failed to zero out quantities", {
            variant: "error",
          });
        });
    });
  };

  return (
    <Dashboard>
      <Paper
        style={{
          padding: "1rem",
          margin: "1rem",
        }}
      >
        <Dialog
          open={confirmDialogOpen}
          onClose={() => {
            setConfirmDialogOpen(false);
          }}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle>Zero out book quantities</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure? There&apos;s no way to un-do this.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setConfirmDialogOpen(false);
              }}
              color="primary"
              startIcon={<CancelIcon />}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                zeroOutQuantities();
                setConfirmDialogOpen(false);
              }}
              color="error"
              startIcon={<LayersClearIcon />}
            >
              Zero out
            </Button>
          </DialogActions>
        </Dialog>

        <Title>Zero out tool</Title>

        <Button
          variant="contained"
          onClick={() => {
            setConfirmDialogOpen(true);
          }}
          startIcon={<LayersClearIcon />}
        >
          Set every book quantity to zero
        </Button>
        <Typography variant="h6">Current Quantity: {booksQuantity}</Typography>
      </Paper>
    </Dashboard>
  );
}
