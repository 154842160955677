import { db } from "./firebase.js";
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { getEnvironment } from "../../utils/environment.ts";
import { collections } from "../../utils/constants.ts";
import type { BookProps } from "../../@types/books.ts";

export const booksCollection = () => {
  switch (getEnvironment()) {
    case "localhost":
      return collection(db, collections.localhost.books);
    case "production":
      return collection(db, collections.production.books);
    default:
      throw new Error("Unknown environment: " + getEnvironment());
  }
};

export const booksCollectionName = () => {
  switch (getEnvironment()) {
    case "localhost":
      return collections.localhost.books;
    case "production":
      return collections.production.books;
    default:
      throw new Error("Unknown environment: " + getEnvironment());
  }
};

export function useBooks() {
  return useCollection(booksCollection(), {});
}

// Function to add a new book to firebase
// Returns a promise that resolves to the new book
export const addBook = (book: BookProps) => {
  const now = new Date();
  const newBook = {
    ...book,
    createdAt: now,
    updatedAt: now,
  };

  return addDoc(booksCollection(), newBook);
};

// Function to update a book in firebase
// Returns a promise that resolves to the updated book
export const updateBook = (book: BookProps) => {
  const now = new Date();
  const updatedBook = {
    ...book,
    updatedAt: now,
  };

  const docRef = doc(db, booksCollectionName() + "/" + book.id);
  return updateDoc(docRef, updatedBook);
};

// Function to delete a book from firebase
// Returns a promise that resolves to the deleted book
export const deleteBook = (book: BookProps) => {
  const docRef = doc(db, booksCollectionName() + "/" + book.id);
  return deleteDoc(docRef);
};

// function generateID() {
//     var arr = new Uint8Array((20 || 40) / 2) // 20 chars long
//     window.crypto.getRandomValues(arr)
//     return Array.from(arr, dec2hex).join('')
// }

// function dec2hex(dec) {
//     return ('0' + dec.toString(36)).substr(-2)
// }
