/* eslint-disable react/prop-types */
import React, { createContext, useState } from "react";
import Grow from "@mui/material/Grow";
import { useTranslation } from "react-i18next";
import { Route, Routes, useParams, useLocation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Book from "./state/Book.js";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSnackbar } from "notistack";

import LogRocket from "logrocket";

// Don't remove these imports of the code splitting won't properly load the firebase or i18n processor
// eslint-disable-next-line
import { db } from "./state/firebase/firebase.js";
import { auth, logout } from "./state/firebase/firebase.js";
import { useBooks } from "./state/firebase/books.ts";
// eslint-disable-next-line
import i18n from "./i18n.ts";
import logger from "./utils/logger.ts";
import { getEnvironment } from "./utils/environment.ts";
import LoadingBooksAnimation from "./components/LoadingBooksAnimation.tsx";
import Login from "./components/Login.jsx";
import Title from "./components/Title.jsx";
import { Dashboard } from "./screens/Dashboard.jsx";
import SetCoverArtScreen from "./screens/SetCoverArt/SetCoverArt.jsx";
import DuplicateBooksScreen from "./screens/DuplicateBookTool.jsx";
import LocationsScreen from "./screens/Locations/locations-screen.jsx";
import BookList from "./screens/master-inventory/master-inventory.jsx";
import AddByISBN from "./screens/AddByISBN.jsx";
import PrintLabels from "./screens/PrintLabels.jsx";
import CheckInOutScreen from "./screens/CheckInOut/Screen.jsx";
import InventoriesScreen from "./screens/SchoolInventory/InventoriesScreen.jsx";
import SchoolInventoryScreen from "./screens/SchoolInventory/InventoryScreen.jsx";
import ZeroOutQuantities from "./screens/ZeroOut/ZeroOut.jsx";
import { MasterInventory } from "./utils/MasterInventory.ts";

export const UserContext = createContext(null);
export const MasterInventoryContext = createContext(new MasterInventory());

function App() {
  // Check if user is authenticated
  const { t } = useTranslation();

  const [user, loading, error] = useAuthState(auth);

  // If user is already logged in, run the app
  if (user) {
    LogRocket.identify(user?.uid, {
      name: user?.displayName ?? "",
      email: user?.email ?? "",
      env: getEnvironment(),
    });

    return <AppAuthenticated currentUser={user} />;
  }

  if (loading) {
    return (
      <LoadingBooksAnimation
        loading={true}
        message={t("loading-authenticating")}
      />
    );
  }

  if (error) {
    logger.error("auth-error", error);
  }

  return <Login />;
}

function AppAuthenticated({ currentUser }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // const MasterInventory = new MasterInventory();

  // Monitor the master inventory
  const [masterInventoryDocs, masterInventoryLoading, masterInventoryError] =
    useBooks();
  const [books, setBooks] = useState([]);
  // When books list changes, print it to console
  // Get the data from each book
  // Convert it into a book object
  // Update the books list
  React.useEffect(() => {
    if (!masterInventoryDocs) return;
    // Print out each book
    const _books = masterInventoryDocs.docs.map((doc) => {
      // Convert doc to Book
      const bookObj = new Book(doc.data());
      bookObj.id = doc.id;

      // add it to the list
      return bookObj;
    });

    setBooks(_books);
  }, [masterInventoryDocs]);

  // When books or loading changes, update the master inventory
  const [masterInventory, setMasterInventory] = useState([]);
  React.useEffect(() => {
    if (masterInventoryError) {
      // If invalid permissions, take the user back to the login screen
      if (masterInventoryError.code === "permission-denied") {
        logout();

        // Show snackbar
        enqueueSnackbar(t("errors.permission-denied"), {
          variant: "error",
        });

        return;
      }
    }
    if (!books) return;
    const mi = new MasterInventory();
    mi.setBooks(books);
    mi.setLoading(masterInventoryLoading);
    mi.setError(masterInventoryError);
    setMasterInventory(mi);
  }, [books, masterInventoryLoading, masterInventoryError, t, enqueueSnackbar]);

  // Get the current location
  const location = useLocation();
  // When location changes, log it
  React.useEffect(() => {
    logger.pageLoad();
  }, [location.pathname]);

  return (
    <>
      <MasterInventoryContext.Provider value={masterInventory}>
        <UserContext.Provider value={currentUser}>
          {/* Full screen error if master inventory didn't load */}
          {masterInventoryError && (
            <Paper
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h4">
                {t("errors.fatal-master-inventory-not-found")}
              </Typography>
            </Paper>
          )}

          {/* Full screen loading if master inventory is loading */}
          {masterInventoryLoading && (
            <LoadingBooksAnimation
              message={t("loading-books")}
              loading={true}
            />
          )}

          {/* If user not logged in, then show login form */}
          {!currentUser && <Login />}

          {/* If user is logged in, then show the app */}
          {currentUser && (
            <Routes>
              <Route path="/" element={<BookList />} />
              <Route path="/login.html" element={<BookList />} />
              <Route path="/books" element={<BookList />} />
              <Route path="/books/addByISBN" element={<AddByISBN />} />
              <Route path="/books/addByISBN/:isbn" element={<AddByISBN />} />
              <Route path="/books/checkInOut" element={<CheckInOutScreen />} />
              <Route path="/books/locations" element={<LocationsScreen />} />
              <Route path="/printLabels" element={<PrintLabels />} />
              <Route path="/inventories" element={<InventoriesScreen />} />
              <Route
                path="/inventories/:inventoryID"
                element={<SchoolInventoryScreen />}
              />

              {/* Admin routes */}
              <Route path="/zeroOut" element={<ZeroOutQuantities />} />
              <Route path="/coverArt" element={<SetCoverArtScreen />} />
              <Route
                path="/duplicateBooks"
                element={<DuplicateBooksScreen />}
              />

              {/* Other */}
              <Route path="*" element={<NotFound />} />
              <Route path="/test/:date" element={<ParamTest />} />
            </Routes>
          )}
        </UserContext.Provider>
      </MasterInventoryContext.Provider>
    </>
  );
}
export default App;

const randomGifs = ["dancing_books.gif", "kitty_reading.gif"];
function NotFound() {
  const { t } = useTranslation();

  const [randomGif, setRandomGif] = useState("");

  // On load, pick a random gif
  React.useEffect(() => {
    const randomGif = randomGifs[Math.floor(Math.random() * randomGifs.length)];
    setRandomGif(randomGif);
  }, []);

  return (
    <Dashboard>
      <Paper
        style={{
          padding: "1rem",
          margin: "1rem",
          textAlign: "center",
        }}
      >
        <Title>{t("errors.page-not-found")}</Title>
        <Grow in={randomGif ? true : false}>
          <img src={"/img/" + randomGif} alt="404" />
        </Grow>
      </Paper>
    </Dashboard>
  );
}

// This works with a path like
// <Route path="/test/:date" element={<ParamTest />} />
function ParamTest() {
  const { date } = useParams();

  return <>{date}</>;
}
