import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Grid,
  Grow,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import React, { useReducer } from "react";
import { useTranslation } from "react-i18next";
import XLSXFromJSON from "../../utils/downloadXLSX.ts";
import { Dashboard } from "../Dashboard";
import { BookListTable } from "./master-inventory-table";
import { MasterInventoryContext } from "../../App";

const flipSortDirection = (currentDirection) => {
  switch (currentDirection) {
    case "asc":
      return "desc";
    case "desc":
      return "asc";
    default:
      return "asc";
  }
};

const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, arguments), delay);
  };
};

const useDebounce = (callback) => {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = React.useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, 500);
  }, []);

  return debouncedCallback;
};

export default function BookList() {
  const masterInventory = React.useContext(MasterInventoryContext);
  const [search, setSearch] = React.useState("");
  const [books, setBooks] = React.useState([]);

  const organizeBooksReducer = (state, action) => {
    switch (action.type) {
      case "SET_PAGE":
        return { ...state, page: action.payload };
      case "SET_QUERY":
        return { ...state, query: action.payload, page: 0 };
      case "SET_SORT":
        return { ...state, sort: action.payload, page: 0 };
      case "SET_ROWS_PER_PAGE":
        if (action.payload * state.page > masterInventory.books.length) {
          return { ...state, rowsPerPage: action.payload, page: 0 };
        }
        return { ...state, rowsPerPage: action.payload };
      case "UPDATE_FILTER":
        return {
          ...state,
          filters: {
            ...state.filters,
            [action.payload.key]: action.payload.value,
          },
        };
      default:
        return state;
    }
  };

  const [organizeBooks, dispatch] = useReducer(organizeBooksReducer, {
    filters: {},
    page: 0,
    query: "",
    rowsPerPage: 10,
    sort: "title|asc",
  });

  React.useEffect(() => {
    // Filter masterInventory.books based on organizeBooks.filters
    let filteredBooks = masterInventory.books || [];

    if (organizeBooks.filters.language?.length) {
      filteredBooks = filteredBooks?.filter(
        (book) => book.language === organizeBooks.filters.language[0]
      );
    }
    if (organizeBooks.filters.type?.length) {
      filteredBooks = filteredBooks?.filter((book) =>
        organizeBooks.filters.type.includes(book.type)
      );
    }
    if (organizeBooks.filters.level?.length) {
      filteredBooks = filteredBooks?.filter((book) =>
        organizeBooks.filters.level.includes(book.level)
      );
    }
    if (organizeBooks.query) {
      filteredBooks = filteredBooks?.filter((book) => {
        const query = organizeBooks.query.toLowerCase();
        return (
          book.title?.toLowerCase().includes(query) ||
          book.author?.toLowerCase().includes(query) ||
          book.isbn?.toLowerCase().includes(query)
        );
      });
    }

    // Sort the filtered books based on organizeBooks.sort
    let sortedBooks = [...filteredBooks].sort((a, b) => {
      const [sortBy, sortDirection] = organizeBooks.sort.split("|");
      if (a[sortBy] < b[sortBy]) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (a[sortBy] > b[sortBy]) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });

    // Set the books to the sorted books
    setBooks(sortedBooks);
  }, [
    masterInventory.books,
    organizeBooks.filters,
    organizeBooks.sort,
    organizeBooks.query,
  ]);

  const { t } = useTranslation();

  const debouncedRequest = useDebounce(() => {
    // send request to the backend
    // access to latest state here
    dispatch({ type: "SET_QUERY", payload: search });
  });

  const handleQueryChange = (event) => {
    event.preventDefault();
    setSearch(event.target.value);
    debouncedRequest();
  };

  const handleTableChangeSort = (property) => {
    if (property !== organizeBooks.sort.split("|")[0]) {
      dispatch({ type: "SET_SORT", payload: `${property}|asc` });
    } else {
      dispatch({
        type: "SET_SORT",
        payload: `${property}|${flipSortDirection(
          organizeBooks.sort.split("|")[1]
        )}`,
      });
    }
  };

  const handlePageChange = (event, newPage) => {
    dispatch({ type: "SET_PAGE", payload: newPage });
  };

  const handleRowsPerPageChange = (event) => {
    dispatch({
      type: "SET_ROWS_PER_PAGE",
      payload: parseInt(event.target.value, 10),
    });
  };

  const downloadXLSX = () => {
    // Today
    const today = new Date();
    const date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    const filename = t("words.inventory") + "-" + date;
    // For each book in the list, convert the array fields to comma separated strings
    let _books = books.map((book) => {
      book.special_category = book.special_category?.join(", ");
      book.source = book.source?.join(", ");
      return book;
    });

    // Download
    XLSXFromJSON(_books, filename);
  };

  const handleUpdateFilter = (key) => (value) => {
    dispatch({ type: "UPDATE_FILTER", payload: { key, value } });
  };

  return (
    <Dashboard
      button={
        <Button
          variant="outlined"
          startIcon={<DownloadIcon />}
          color="secondary"
          onClick={() => {
            downloadXLSX();
          }}
          sx={{ m: 1 }}
        >
          {t("buttons.download-inventory")}
        </Button>
      }
      title={t("titles.master-inventory")}
    >
      <Grow in={true}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexWrap: "wrap",
                  m: -1.5,
                  p: 1,
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    m: 1.5,
                  }}
                >
                  <TextField
                    fullWidth
                    autoFocus={true}
                    onChange={handleQueryChange}
                    value={search}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t("placeholders.search-books")}
                  />
                </Box>
              </Box>
              <BookListTable
                books={books.slice(
                  organizeBooks.page * organizeBooks.rowsPerPage,
                  organizeBooks.page * organizeBooks.rowsPerPage +
                    organizeBooks.rowsPerPage
                )}
                booksCount={books.length || -1}
                totalBooksQuantity={books.reduce(
                  (acc, book) => acc + book.quantity,
                  0
                )}
                loadingBooks={!!masterInventory.loading}
                handleUpdateFilter={handleUpdateFilter}
                onChangeSort={handleTableChangeSort}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPage={organizeBooks.rowsPerPage}
                page={organizeBooks.page}
                sort={organizeBooks.sort}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grow>
    </Dashboard>
  );
}
