import React from "react";
import { signInWithGoogle } from "../state/firebase/firebase";
import Title from "./Title";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import GoogleIcon from "@mui/icons-material/Google";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

function Login() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Paper
      elevation={5}
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "50vh",
        width: "90vw",
        maxWidth: "500px",
        margin: "auto",
        backgroundColor: "#fff",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translateY(-50%) translateX(-50%)",
      }}
    >
      <Typography variant="h1">
        Inven<sup>Story</sup>
      </Typography>
      <img src="/img/icon-book.png" alt="logo" width="50%" />
      <br />
      <Title>{t("labels.must-login")}</Title>
      <Button
        startIcon={<GoogleIcon />}
        variant="contained"
        onClick={() => {
          try {
            signInWithGoogle()
              .then(() => {
                console.log("ok");
              })
              .catch((err) => {
                // If error is auth/popup-blocked, then show a message
                if (err.code === "auth/popup-blocked") {
                  enqueueSnackbar(t("errors.enable-popups"), {
                    variant: "warning",
                    autoHideDuration: 10000,
                  });
                } else {
                  enqueueSnackbar(t("errors.login-failed"), {
                    variant: "error",
                    autoHideDuration: 10000,
                  });
                }
              });
          } catch (err) {
            console.log("::::" + err);
          }
        }}
      >
        {t("buttons.login-with-google")}
      </Button>
    </Paper>
  );
}

export default Login;
