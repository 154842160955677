import { Button, Grid, Paper } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { MasterInventoryContext } from "../../App";
import { Dashboard } from "../../screens/Dashboard";
import { useSnackbar } from "notistack";
import { updateBook } from "../../state/firebase/books.ts";

export default function SetCoverArtScreen() {
  const { enqueueSnackbar } = useSnackbar();
  // Get entire master inventory
  const masterInventory = useContext(MasterInventoryContext);

  const [currentBook, setCurrentBook] = React.useState(null);

  const [seenIDs, setSeenIDs] = React.useState([]);

  const findBook = () => {
    // Iterate through master inventory books
    for (let i = 0; i < masterInventory.books.length; i++) {
      const book = masterInventory.books[i];

      // If book already has cover art, move on
      if (book.thumbnail) {
        continue;
      }

      // If the book has not been seen yet
      if (!seenIDs.includes(book.id)) {
        // Set the current book
        setCurrentBook(book);

        // Add the book to the seen IDs
        setSeenIDs([...seenIDs, book.id]);

        // Break out of the loop
        break;
      }
    }
  };

  return (
    <Dashboard>
      <Paper
        style={{
          padding: "1rem",
          margin: "1rem",
        }}
      >
        <Button
          onClick={() => {
            findBook();
          }}
        >
          Get next book
        </Button>
        {currentBook && (
          <>
            <h1>{currentBook.title}</h1>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <img
                  src={
                    "https://pictures.abebooks.com/isbn/" +
                    currentBook.isbn +
                    ".jpg"
                  }
                  style={{
                    minWidth: "200px",
                    minHeight: "200px",
                  }}
                  alt={currentBook.title}
                />
              </Grid>
              <Grid item xs={8}>
                Title: {currentBook.title}
                <br />
                Author: {currentBook.author}
                <br />
                ISBN: {currentBook.isbn}
                <br />
                Current Cover: {currentBook.cover}
                <br />
                Current Thumbnail: {currentBook.thumbnail}
                <br />
                URL:{" "}
                <a
                  href={
                    "https://pictures.abebooks.com/isbn/" +
                    currentBook.isbn +
                    ".jpg"
                  }
                >
                  https://pictures.abebooks.com/isbn/{currentBook.isbn}.jpg
                </a>
                <Button
                  fullWidth={true}
                  variant="contained"
                  style={{
                    marginTop: "2rem",
                  }}
                  onClick={() => {
                    let book = currentBook;

                    // Update book record
                    book.thumbnail =
                      "https://pictures.abebooks.com/isbn/" +
                      currentBook.isbn +
                      ".jpg";
                    book.cover = book.thumbnail;

                    updateBook(currentBook)
                      .then(() => {
                        console.log("Book updated: ", book.id);
                        enqueueSnackbar("Book updated: " + book.id, {
                          variant: "success",
                        });

                        // Find next book
                        findBook();
                      })
                      .catch((err) => {
                        console.error("Book update error: ", err);

                        enqueueSnackbar("Book update error: " + err, {
                          variant: "error",
                        });
                      });
                  }}
                >
                  Use this cover art
                </Button>
                <Button
                  fullWidth={true}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    findBook();
                  }}
                  style={{
                    marginTop: "2rem",
                  }}
                >
                  Skip & next
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </Dashboard>
  );
}
