import * as React from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

ScanForm.propTypes = {
  mode: PropTypes.oneOf(["school", "receipt"]).isRequired,
  onDataReady: PropTypes.func.isRequired,
  maintainFocus: PropTypes.bool.isRequired,
};

export default function ScanForm(props) {
  const { t } = useTranslation();

  const [isbn, setIsbn] = React.useState("");
  const [code, setCode] = React.useState("");

  const [submitError, setSubmitError] = React.useState(null);

  const isbnRef = React.useRef();
  const codeRef = React.useRef();

  // function for focusing to one of the text fields
  const focus = (ref) => {
    ref.current.focus();
  };

  const handleClear = () => {
    setIsbn("");
    setCode("");

    setSubmitError(null);

    focus(isbnRef);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let _isbn = isbn;
    let _code = code;

    // Submit might have happened by the scanner pressing "enter" after entering the data
    // If so, check if either field is empty. If it's empty, then focus on the other field
    if (_isbn.length === 0) {
      focus(isbnRef);
      return;
    } else if (_code.length === 0 && props.mode === "school") {
      // Only care about the code field if we're in school mode
      focus(codeRef);
      return;
    }

    // accept either isbn or code in either field.
    // Ib ISBN has the deliniator characters, then switch it
    if (_isbn.includes(";") || _isbn.includes("ñ")) {
      console.log(
        "Flip flop! Codes were scanned in reverse but that's OK  ✈️👻☮️ "
      );
      const oldIsbn = _isbn;
      const oldCode = _code;

      _isbn = oldCode;
      _code = oldIsbn;
    }

    // Require ISBN
    if (_isbn.length === 0) {
      handleClear(e);
      setSubmitError(t("errors.isbn-required"));
      return;
    }

    let codeParts = [];

    switch (props.mode) {
      case "school":
        // Split code on semicolon. The first part is the letter and the second is the number

        // Sometimes it's read as a semicolon
        if (_code.includes(";")) {
          codeParts = _code.split(";");
          if (codeParts.length !== 2) {
            handleClear(e);
            setSubmitError(t("errors.invalid-code", { code: _code }));
            return;
          }
        } else if (_code.includes("ñ")) {
          codeParts = _code.split("ñ");
          if (codeParts.length !== 2) {
            handleClear(e);
            setSubmitError(t("errors.invalid-code", { code: _code }));
            return;
          }
        } else {
          setSubmitError(t("errors.invalid-code", { code: _code }));
          handleClear(e);
          return;
        }

        // Make sure code is numeric
        codeParts[1] = parseInt(codeParts[1], 10);
        if (isNaN(codeParts[1])) {
          handleClear(e);
          setSubmitError(t("errors.invalid-code", { code: _code }));
          return;
        }

        props.onDataReady({
          isbn: _isbn,
          letter: codeParts[0],
          number: codeParts[1],
        });
        handleClear(e);
        break;
      case "receipt":
        // Just submit ISBN
        props.onDataReady({
          isbn: _isbn,
        });
        handleClear(e);
        break;
      default:
        console.error("Invalid mode: " + props.mode);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label={t("labels.isbn")}
            value={isbn}
            onChange={(e) => setIsbn(e.target.value)}
            fullWidth
            inputRef={isbnRef}
            autoFocus={true}
            name="isbn"
          />
        </Grid>
        {props.mode === "school" && (
          <Grid item xs={12}>
            <TextField
              label={t("labels.code")}
              value={code}
              onChange={(e) => setCode(e.target.value)}
              fullWidth
              inputRef={codeRef}
              name="code"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Collapse in={submitError && submitError.length > 0}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {submitError ? submitError : ""}
            </Alert>
          </Collapse>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="success"
            type="submit"
            startIcon={<SaveIcon />}
          >
            {t("buttons.save")}
          </Button>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={(e) => {
              handleClear(e);
            }}
            startIcon={<CancelIcon />}
          >
            {t("buttons.clear")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
