import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grow from "@mui/material/Grow";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import SchoolInventory from "../../state/SchoolInventory.js";
import SchoolInventoryMetadataForm from "./MetadataForm.jsx";

SchoolInventoryMetadataDialog.propTypes = {
  schoolInventory: PropTypes.instanceOf(SchoolInventory).isRequired,
  mode: PropTypes.oneOf(["edit", "create", "delete"]).isRequired,
  open: PropTypes.bool.isRequired, // the outside world controls if we're open or not
  done: PropTypes.func.isRequired, // When we're done (cancel button, save is complete, ...) let the world know so they can close us
};

export default function SchoolInventoryMetadataDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { t } = useTranslation();

  const handleDismiss = () => {
    props.done();
  };
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      onClose={handleDismiss}
      aria-labelledby="alert-dialog-title"
      fullWidth={props.mode === "edit" || props.mode === "create"}
      maxWidth={"lg"}
      fullScreen={fullScreen}
    >
      <DialogTitle id="alert-dialog-title">
        {props.mode === "create" ? t("titles.create-inventory") : ""}
        {props.mode === "edit" ? t("titles.edit-inventory") : ""}
        {props.mode === "delete" ? t("titles.delete-inventory") : ""}
      </DialogTitle>
      <DialogContent>
        <SchoolInventoryMetadataForm
          schoolInventory={props.schoolInventory}
          mode={props.mode}
          onComplete={() => {
            console.log(
              "on complete handler called so schoolInventory must be saved!"
            );

            // Close the Dialog
            handleDismiss();
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleDismiss();
          }}
          startIcon={<CancelIcon />}
        >
          {t("buttons.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      timeout={1000}
      ref={ref}
      {...props}
      {...{ timeout: 600 }} // why do I have to use this weird syntax?
    />
  );
});
