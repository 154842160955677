import * as React from "react";

import Backdrop from "@mui/material/Backdrop";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { useTranslation } from "react-i18next";

export default function LoadingBooksAnimation({
  loading,
  message,
}: {
  loading: boolean;
  message?: string;
}) {
  const { t } = useTranslation();

  // If timer prop is not set, use a default value
  // @todo - make this switch to an indeterminate progress bar instead

  // Need to have the progress bar be gated by props.loading so the timer resets each time the module is re "opened"

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <Grid container spacing={10}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            {/* <CircularProgress size={200} thickness={2} /> */}
            <div className="bg applicationLoader shadow"></div>
            <div className="bookshelf_wrapper applicationLoader">
              <ul className="books_list">
                <li className="book_item first"></li>
                <li className="book_item second"></li>
                <li className="book_item third"></li>
                <li className="book_item fourth"></li>
                <li className="book_item fifth"></li>
                <li className="book_item sixth"></li>
              </ul>
              <div className="shelf"></div>
              <div className="loading">{t("loading")}...</div>
              {message && <div className="loading_text">{message}</div>}
            </div>
          </Box>
        </Grid>
      </Grid>
    </Backdrop>
  );
}
