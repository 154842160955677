import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import type { BookProps } from "../@types/books";

export default function XLSXFromJSON(data: Array<BookProps>, filename: string) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  // If filename does not end with .xlsx, add it
  if (!filename.endsWith(fileExtension)) {
    filename += fileExtension;
  }

  // Generate excel file from the data
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  // XLSX.writeFile(wb, "InvenStory.xlsx");
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const rawData = new Blob([excelBuffer], { type: fileType });

  // Download that file
  FileSaver.saveAs(rawData, filename);
}
