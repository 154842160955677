import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { updateBook } from "../../state/firebase/books.ts";
import { Dashboard } from "../Dashboard";
import LoadingBackdrop from "../../components/LoadingBackdrop.tsx";
import { MasterInventoryContext } from "../../App";
import { useNavigate } from "react-router-dom";
export default function CheckInOutScreen() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState(false);

  const [mode, setMode] = React.useState("check-in");
  const [isbn, setIsbn] = React.useState("");

  const [book, setBook] = React.useState(null);

  const isbnRef = React.useRef(null);
  let navigate = useNavigate();

  const masterInventory = React.useContext(MasterInventoryContext);

  const clearForm = () => {
    setIsbn("");
    isbnRef.current.focus();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (isbn.length === 0) return;

    // Find that isbn in the master inventory
    const book = masterInventory.searchByISBN(isbn);
    if (!book) {
      enqueueSnackbar(t("errors.isbn-not-found"), {
        variant: "error",
      });
      setBook(null);
      clearForm();

      navigate("/books/addByISBN/" + isbn);

      return;
    }

    // Increase or decrease the quantity
    if (mode === "check-in") {
      book.quantity++;
    } else {
      book.quantity--;
    }

    // Cap the quantity at 0
    if (book.quantity < 0) {
      book.quantity = 0;
      enqueueSnackbar(t("errors.books-gone"), {
        variant: "warning",
      });
    }

    // Update the book
    updateBook(book)
      .then(() => {
        console.log("Book updated: ", book.id);

        book.updatedAt = new Date();

        enqueueSnackbar(t("alerts.book-saved"), {
          variant: "success",
          autoHideDuration: 3000,
        });

        setBook(book);
      })
      .catch((err) => {
        // @todo display error
        console.error("Error saving book: ", err);
        enqueueSnackbar(t("errors.book-save"), {
          variant: "error",
          autoHideDuration: 5000,
        });

        setBook(book);
      })
      .finally(() => {
        setLoading(false);
        clearForm();
      });
  };

  return (
    <Dashboard title={t("titles.check-in-out")}>
      <Grow in={true}>
        <Grid container spacing={3}>
          {/* Left column */}
          <Grid item xs={12} md={8} lg={9}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LoadingBackdrop
                loading={loading || masterInventory.loading}
                timer={5}
              />
              <form onSubmit={onSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    <TextField
                      name="isbn"
                      label={t("labels.isbn")}
                      variant="outlined"
                      fullWidth
                      ref={isbnRef}
                      value={isbn}
                      onChange={(e) => setIsbn(e.target.value)}
                      autoFocus={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color={mode === "check-in" ? "success" : "warning"}
                      type="submit"
                      size="large"
                      startIcon={<ArrowCircleRightIcon />}
                    >
                      {mode === "check-in"
                        ? t("buttons.check-in")
                        : t("buttons.check-out")}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    {book && (
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>{t("labels.isbn")}</TableCell>
                              <TableCell>{t("labels.title")}</TableCell>
                              <TableCell>{t("labels.author")}</TableCell>
                              <TableCell>{t("labels.reading-level")}</TableCell>
                              <TableCell>{t("labels.quantity")}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>{book.isbn}</TableCell>
                              <TableCell>{book.title}</TableCell>
                              <TableCell>{book.author}</TableCell>
                              <TableCell>{t(book.level)}</TableCell>
                              <TableCell>{book.quantity}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
          {/* Right column */}
          <Grid item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      name="mode"
                      checked={mode === "check-in"}
                      color="warning"
                      onChange={(e) =>
                        setMode(e.target.checked ? "check-in" : "check-out")
                      }
                    />
                  }
                  label={
                    mode === "check-in"
                      ? t("buttons.check-in")
                      : t("buttons.check-out")
                  }
                />
              </FormGroup>
              <Typography variant="h5">
                {mode === "check-in"
                  ? t("check-in-description")
                  : t("check-out-description")}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grow>
    </Dashboard>
  );
}
