import React from "react";
import { Box, Skeleton, TableCell, TableRow } from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const SkeletonRow = () => (
  <TableRow>
    <TableCell>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <Skeleton variant="circular" width={42} height={42} />
        <Box sx={{ ml: 1, flex: 1 }}>
          <Typography variant="subtitle2">
            <Skeleton width={150} />
          </Typography>
          <Typography color="textSecondary" variant="body2">
            <Skeleton />
          </Typography>
        </Box>
      </Box>
    </TableCell>
    <TableCell>
      <Typography color="textSecondary" variant="body2" align="right">
        <Skeleton />
      </Typography>
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell align="right">
      <IconButton disabled variant="contained" color="primary" size="small">
        <EditIcon />
      </IconButton>
      <IconButton disabled variant="contained" color="error" size="small">
        <DeleteIcon />
      </IconButton>
    </TableCell>
  </TableRow>
);
