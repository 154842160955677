import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // debug: true,
    // fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // whitelist: ["en", "es"],
    backend: {
      // loadPath: '/locales/{{lng}}/{{ns}}.json',
      loadPath: "/locales/{{lng}}/translation.json",
    },
  });

export default i18n;


// export const defaultNS = "ns1";
// export const resources = {
//   en: {
//     ns1,
//     ns2,
//   },
// } as const;

// i18n.use(initReactI18next).init({
//   lng: "en",
//   ns: ["ns1", "ns2"],
//   defaultNS,
//   resources,
// });

// export default i18n;