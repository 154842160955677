import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import RemoveIcon from "@mui/icons-material/Remove";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
// import Avatar from "@mui/material/Avatar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
// import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Dashboard } from "../Dashboard";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import Book from "../../state/Book";
import {
  addSchoolInventoryEntry,
  deleteSchoolInventoryEntry,
  updateSchoolInventoryEntry,
  useSchoolInventoryEntries,
} from "../../state/firebase/schoolInventoryEntries";
import { useSchoolInventory } from "../../state/firebase/schoolInventories";
import SchoolInventory from "../../state/SchoolInventory";
import SchoolInventoryEntry from "../../state/SchoolInventoryEntry";
import XLSXFromJSON from "../../utils/downloadXLSX.ts";
import LoadingBackdrop from "../../components/LoadingBackdrop.tsx";
import Title from "../../components/Title";
import ScanForm from "./ScanForm";
import { MasterInventoryContext } from "../../App";
import { MasterInventory } from "../../utils/MasterInventory.ts";
import { Scrollbar } from "../../components/scrollbar";
import { updateBook } from "../../state/firebase/books.ts";

// Wrap the screen with a getter for the school inventory
export default function SchoolInventoryScreen() {
  const { inventoryID } = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [schoolInventoriesDocs, loading, error] = useSchoolInventory();

  const [notFound, setNotFound] = React.useState(false);

  const [schoolInventory, setSchoolInventory] = React.useState(null);

  // When the docset gets a new snapshot, update the school inventories var
  React.useEffect(() => {
    if (!schoolInventoriesDocs) return;

    // Loop through the docs until we find an ID match
    for (let i = 0; i < schoolInventoriesDocs.docs.length; i++) {
      const doc = schoolInventoriesDocs.docs[i];
      if (doc.id === inventoryID) {
        let si = new SchoolInventory(doc.data());
        si.id = doc.id;
        setSchoolInventory(si);
        return;
      }
    }

    // If we got this far, the inventory wasn't found
    setNotFound(true);
  }, [schoolInventoriesDocs, inventoryID]);

  // Watch for changes to notFound so we can redirect
  React.useEffect(() => {
    // Set a timer to go back to the inventory list in 5 seconds
    if (notFound) {
      let timeout = setTimeout(() => {
        navigate("/inventories");
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [notFound, navigate]);

  if (loading) {
    return <LoadingBackdrop loading={true} timer={10} />;
  } else if (error) {
    return (
      <Dashboard>
        <Alert severity="error">
          <AlertTitle>{t("errors.error")}</AlertTitle>
          {JSON.stringify(error)}
        </Alert>
      </Dashboard>
    );
  } else if (notFound) {
    return (
      <Dashboard>
        <Alert severity="error">
          <AlertTitle>{t("errors.error")}</AlertTitle>
          {t("errors.inventory-not-found")}
        </Alert>
      </Dashboard>
    );
  } else if (schoolInventory) {
    return <SchoolInventoryScreenInner schoolInventory={schoolInventory} />;
  }

  return <LoadingBackdrop loading={true} timer={10} />;
}

function SchoolInventoryScreenInner(props) {
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [entryGroups, setEntryGroups] = React.useState({
    A: [{ start: 1, end: 5 }],
  });

  const masterInventory = React.useContext(MasterInventoryContext);

  // Keep track of this school's inventory
  const [inventoryEntryDocs, inventoryEntryLoading, inventoryEntryError] =
    useSchoolInventoryEntries(props.schoolInventory.id);
  const [entries, setEntries] = React.useState([]);
  React.useEffect(() => {
    if (!inventoryEntryDocs) return;
    // Print out each entry
    let _entries = inventoryEntryDocs.docs.map((doc) => {
      // Convert doc to Entry
      let entry = new SchoolInventoryEntry(doc.data());
      entry.id = doc.id;

      // For each entry, as a convenience, add the book to the entry
      let book = masterInventory.searchByID(entry.bookID);
      if (book) {
        entry.book = book;
      } else {
        // If the book isn't found, add a dummy book
        entry.book = new Book({
          id: entry.bookID,
          title: "Book Not Found",
        });
      }

      // add it to the list
      return entry;
    });

    setEntries(_entries);
  }, [inventoryEntryDocs, masterInventory]);

  // Helper for changing the book quantity in the master inventory
  const setBookQuantity = (bookID, adjustment) => {
    // Big note! the adjustments are in the local context, so +1 means 1 more in THIS inventory
    // SO we have to inverse the adjustment for the master inventory
    adjustment = -adjustment;

    console.log("Adjusting book quantity", bookID, adjustment);

    // Update the book quantity in master inventory
    let book = masterInventory.searchByID(bookID);
    book.quantity += adjustment;
    if (book.quantity < 0) {
      enqueueSnackbar(t("errors.books-gone", { title: book.title }), {
        variant: "info",
      });
      return;
    }

    updateBook(book)
      .then(() => {
        console.log(
          "Book updated in master inventory to quantity: " + book.quantity
        );
      })
      .catch((error) => {
        console.error("Error saving book into master inventory: ", error);
        enqueueSnackbar(t("errors.book-save"), {
          variant: "error",
          autoHideDuration: 5000,
        });
      });
  };

  // Handler for updating an entry. Uses the ID prop of the entry itself for indexing
  const changeQuantity = (entry, adjustment) => {
    console.log("Adjusting entry quantity", entry.id, adjustment);
    // Update entry obj
    entry.quantity += adjustment;

    // Update the entry records
    setLoading(true);
    updateSchoolInventoryEntry(entry)
      .then(() => {
        // Announce success
        enqueueSnackbar(t("alerts.school-inventory-entry-updated"), {
          variant: "success",
          autoHideDuration: 3000,
        });

        setBookQuantity(entry.bookID, adjustment);
      })
      .catch((error) => {
        // Announce error
        console.error("error updating book from inventory", error);
        enqueueSnackbar(t("alerts.school-inventory-entry-update-error"), {
          variant: "error",
          autoHideDuration: 3000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Handler for creating a new entry.
  const entryCreate = (entry) => {
    setLoading(true);
    addSchoolInventoryEntry(entry)
      .then((ref) => {
        console.log("Added entry:", ref.id);

        // Set ID, updated at, and created at:
        entry.id = ref.id;
        entry.updatedAt = new Date();
        entry.createdAt = new Date();

        // Success!!
        enqueueSnackbar(t("alerts.entry-saved"), {
          variant: "success",
          autoHideDuration: 3000,
        });

        setBookQuantity(entry.bookID, +1);
      })
      .catch((error) => {
        console.log("Error adding entry:", error);
        setSubmitError(t("errors.error-adding-entry"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // When the entry list changes, update the entry groups
  React.useEffect(() => {
    if (!entries) return;
    if (entries.length === 0) return;

    // console.log("Entries:");
    // console.table(entries);

    setEntryGroups(groupEntriesByLetter(entries));
  }, [entries]);

  // Helper for downloading the inventory as an XLSX file
  const downloadXLSX = () => {
    // download
    // Copy array
    let _entries = entries.map((entry) => {
      let foundBook = masterInventory.searchByID(entry.bookID);
      if (!foundBook) {
        console.error("Could not find book with ID:", entry.bookID);
        return null;
      }

      // Add title & author
      entry.title = foundBook.title;
      entry.author = foundBook.author;

      // If receipt type, remove letter and number
      if (props.schoolInventory.type === "receipt") {
        delete entry.letter;
        delete entry.number;
      }

      // If school type, then add a "code" field
      if (props.schoolInventory.type === "school") {
        entry.code = formattedCode(entry.letter, entry.number);
      }

      // Remove list ID
      delete entry.listID;

      // Remove ID
      delete entry.id;

      return { title: entry.title, author: entry.author, code: entry.code };
      // return entry;
    });

    _entries.sort((a, b) => {
      if (a.code > b.code) {
        return -1;
      }
      return 1;
    });

    // todays date
    XLSXFromJSON(_entries, props.schoolInventory.name);
  };

  return (
    <Dashboard
      title={t("titles.school-inventory")}
      subtitle={props.schoolInventory.name}
      button={
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<DownloadIcon />}
          onClick={() => {
            downloadXLSX();
          }}
        >
          {t("buttons.download-inventory")}
        </Button>
      }
    >
      <Grow in={true}>
        <Grid container spacing={3}>
          {/* @todo could change to column-reverse when the screen is small */}
          <Grid item xs={12} md={9}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Collapse in={submitError && submitError.length > 0}>
                  <Alert severity="error">
                    <AlertTitle>{t("errors.error")}</AlertTitle>
                    {submitError ? submitError : ""}
                  </Alert>
                </Collapse>
                <Collapse
                  in={inventoryEntryError && inventoryEntryError.length > 0}
                >
                  <Alert severity="error">
                    <AlertTitle>{t("errors.error")}</AlertTitle>
                    {inventoryEntryError ? inventoryEntryError : ""}
                  </Alert>
                </Collapse>
              </Grid>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <LoadingBackdrop
                    loading={loading || inventoryEntryLoading}
                    timer={30}
                  />
                  {entries.length > 0 ? (
                    <SchoolInventoryTableFancy
                      schoolInventory={props.schoolInventory}
                      entries={entries}
                      masterInventory={masterInventory}
                      onDelete={(entry) => {
                        // Remove this entry from the db
                        setLoading(true);
                        deleteSchoolInventoryEntry(entry)
                          .then(() => {
                            // Announce success
                            enqueueSnackbar(
                              t("alerts.school-inventory-entry-deleted"),
                              {
                                variant: "success",
                                autoHideDuration: 3000,
                              }
                            );

                            setBookQuantity(entry.bookID, -entry.quantity);
                          })
                          .catch((error) => {
                            // Announce error
                            console.error(
                              "error deleting book from inventory",
                              error
                            );
                            enqueueSnackbar(
                              t("alerts.school-inventory-entry-delete-error"),
                              {
                                variant: "error",
                                autoHideDuration: 3000,
                              }
                            );
                          })
                          .finally(() => {
                            setLoading(false);
                          });
                      }}
                      minusOne={(entry) => {
                        changeQuantity(entry, -1);
                      }}
                      plusOne={(entry) => {
                        changeQuantity(entry, +1);
                      }}
                    />
                  ) : (
                    <div>{t("empty-state.no-books")}</div>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ScanForm
                    maintainFocus={true}
                    onDataReady={(data) => {
                      setSubmitError(null);
                      console.log("ISBN:", data.isbn);

                      // Require ISBN to be found in the database
                      // Loop through books list looking for it
                      let foundBook = masterInventory.searchByISBN(data.isbn);
                      if (!foundBook) {
                        setSubmitError(t("errors.book-not-found"));
                        enqueueSnackbar(t("errors.book-not-found"), {
                          variant: "error",
                          autoHideDuration: 15000,
                        });
                        return;
                      }

                      // Check if just the ISBN is in the inventory. If so, add it, but also display
                      // an alert saying it is in there under another code
                      let foundISBN = entries.find((entry) => {
                        return entry.bookID === foundBook.id;
                      });

                      switch (props.schoolInventory.type) {
                        case "school": {
                          let foundCode = entries.find((entry) => {
                            return (
                              entry.letter === data.letter &&
                              entry.number === data.number
                            );
                          });
                          // Check if code is already in the list. Cannot duplicate codes

                          // If this matches an isbn+code combination, just do nothing. the book was already scnned in
                          if (foundCode && foundISBN) {
                            enqueueSnackbar(
                              t("alerts.book-already-in-inventory", {
                                title: foundBook.title,
                              }),
                              {
                                variant: "info",
                                autoHideDuration: 10000,
                              }
                            );
                            return;
                          }

                          if (foundCode) {
                            setSubmitError(
                              t("errors.code-in-use", {
                                title: foundBook.title,
                                code: formattedCode(
                                  foundISBN.letter,
                                  foundISBN.number
                                ),
                              })
                            );
                            return;
                          }

                          if (foundISBN) {
                            // pass the other entry's code to the alert
                            enqueueSnackbar(
                              t("alerts.book-already-in-inventory-with-code", {
                                title: foundBook.title,
                                code: formattedCode(
                                  foundISBN.letter,
                                  foundISBN.number
                                ),
                              }),
                              {
                                variant: "info",
                                autoHideDuration: 10000,
                              }
                            );
                          }

                          // Insert the entry
                          const entry = new SchoolInventoryEntry({
                            listID: props.schoolInventory.id,
                            bookID: foundBook.id,
                            letter: data.letter,
                            number: data.number,
                            quantity: 1,
                          });
                          entryCreate(entry);

                          break;
                        }
                        case "receipt": {
                          // if the book is already in the list, increase the quantity
                          if (foundISBN) {
                            const entry = new SchoolInventoryEntry(foundISBN);
                            changeQuantity(entry, +1);
                          } else {
                            //  if the book is not in the list, add it w/ quantity=1
                            const entry = new SchoolInventoryEntry({
                              listID: props.schoolInventory.id,
                              bookID: foundBook.id,
                              quantity: 1,
                            });
                            entryCreate(entry);
                          }
                          break;
                        }
                        default:
                          console.error(
                            "Unknown inventory type: ",
                            props.schoolInventory.type
                          );
                      }
                    }}
                    mode={props.schoolInventory.type}
                  />
                </Grid>
                {props.schoolInventory.type === "school" ? (
                  <Grid item xs={12}>
                    <Title>{t("titles.codes-used")}</Title>
                    {entryGroups &&
                      Object.keys(entryGroups).map((letter, idx) => {
                        // For each letter, print a section that contains the used ranges from start to end
                        const entry = entryGroups[letter];
                        return (
                          <React.Fragment key={letter + idx}>
                            <Typography variant="h6">{letter}</Typography>
                            <Typography variant="body2">
                              {entry.map((group, idx) => {
                                return (
                                  <React.Fragment key={idx}>
                                    {formattedCode(letter, group.start)} -{" "}
                                    {formattedCode(letter, group.end)}
                                    <br />
                                  </React.Fragment>
                                );
                              })}
                            </Typography>
                          </React.Fragment>
                        );
                      })}
                  </Grid>
                ) : null}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grow>
    </Dashboard>
  );
}

const applyFilters = (masterInventory, entries, filters) => {
  if (!entries) return [];
  return entries.filter((entry) => {
    if (filters.query) {
      let queryMatched = false;

      // match book fields
      let book = masterInventory.searchByID(entry.bookID);
      if (book) {
        const properties = ["author", "title"];
        properties.forEach((property) => {
          if (
            book[property].toLowerCase().includes(filters.query.toLowerCase())
          ) {
            queryMatched = true;
            console.log("Entry matched: ", entry);
            console.log("Property: ", property);
            console.log("Query: ", filters.query);
          }
        });

        const code = formattedCode(entry.letter, entry.number).toLowerCase();
        const found = code.includes(filters.query.toLowerCase());

        console.log(code, found);

        if (
          formattedCode(entry.letter, entry.number)
            .toLowerCase()
            .includes(filters.query.toLowerCase())
        ) {
          queryMatched = true;
        }

        if (!queryMatched) {
          return false;
        }
      }
    }

    // @todo other chances to match here

    // Then return true by default
    return true;
  });
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy) =>
  order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

const applySort = (items, sort) => {
  if (!items) return [];
  const [orderBy, order] = sort.split("|");
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = items.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    // If sorting by title or author, reference the book instead
    if (orderBy === "title") {
      if (order === "asc") {
        return a[0].book.title > b[0].book.title;
      } else {
        return a[0].book.title < b[0].book.title;
      }
    }
    if (orderBy === "author") {
      if (order === "asc") {
        return a[0].book.author > b[0].book.author;
      } else {
        return a[0].book.author < b[0].book.author;
      }
    }

    // // If sorting by code, then use the formatted code
    if (orderBy === "code") {
      if (order === "asc") {
        return (
          formattedCode(a[0].letter, a[0].number) >
          formattedCode(b[0].letter, b[0].number)
        );
      } else {
        return (
          formattedCode(a[0].letter, a[0].number) <
          formattedCode(b[0].letter, b[0].number)
        );
      }
    }

    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

const applyPagination = (items, page, rowsPerPage) =>
  items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

SchoolInventoryTableFancy.propTypes = {
  schoolInventory: PropTypes.object.isRequired,
  entries: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  plusOne: PropTypes.func.isRequired,
  minusOne: PropTypes.func.isRequired,
  masterInventory: PropTypes.instanceOf(MasterInventory).isRequired,
};

function SchoolInventoryTableFancy(props) {
  const { t } = useTranslation();

  const entries = props.entries;

  // These options are always there
  let sortOptions = [
    {
      label: t("sort.title"),
      value: "title|asc",
    },
    {
      label: t("sort.author"),
      value: "author|asc",
    },
    {
      label: t("sort.updated-at") + " (" + t("sort.desc") + ")",
      value: "updatedAt|desc",
    },
    {
      label: t("sort.updated-at") + " (" + t("sort.asc") + ")",
      value: "updatedAt|asc",
    },
  ];

  // Add specific options for each inventory type
  if (props.schoolInventory.type === "receipt") {
    sortOptions.push(
      {
        label: t("sort.quantity") + " (" + t("sort.desc") + ")",
        value: "quantity|desc",
      },
      {
        label: t("sort.quantity") + " (" + t("sort.asc") + ")",
        value: "quantity|asc",
      }
    );
  } else {
    sortOptions.push(
      {
        label: t("sort.code") + " (" + t("sort.desc") + ")",
        value: "code|desc",
      },
      {
        label: t("sort.code") + " (" + t("sort.asc") + ")",
        value: "code|asc",
      }
    );
  }

  const queryRef = React.useRef(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sort, setSort] = React.useState(sortOptions[0].value);
  const [filters, setFilters] = React.useState({
    query: "",
  });

  const handleQueryChange = (event) => {
    event.preventDefault();

    // After applying filters, we need to go back to the first page
    setPage(0);

    setFilters((prevState) => ({
      ...prevState,
      query: queryRef.current?.value,
    }));
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // Usually query is done on backend with indexing solutions
  const filteredEntries = applyFilters(props.masterInventory, entries, filters);
  const sortedEntries = applySort(filteredEntries, sort);
  const paginatedEntries = applyPagination(sortedEntries, page, rowsPerPage);

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          m: -1.5,
          p: 3,
        }}
      >
        <Box
          component="form"
          onSubmit={handleQueryChange}
          sx={{
            flexGrow: 1,
            m: 1.5,
          }}
        >
          <TextField
            defaultValue=""
            fullWidth
            size="small"
            inputProps={{ ref: queryRef }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            placeholder={t("placeholders.search-entries")}
          />
        </Box>
        <TextField
          label={t("sort.sort-by")}
          name="sort"
          onChange={handleSortChange}
          select
          size="small"
          SelectProps={{ native: true }}
          sx={{ m: 1.5 }}
          value={sort}
        >
          {sortOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </Box>
      <EntriesListTable
        entries={paginatedEntries}
        entriesCount={filteredEntries.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPage={rowsPerPage}
        page={page}
        masterInventory={props.masterInventory}
        schoolInventory={props.schoolInventory}
        plusOne={props.plusOne}
        minusOne={props.minusOne}
        onDelete={props.onDelete}
      />
    </>
  );
}

EntriesListTable.propTypes = {
  entries: PropTypes.array.isRequired,
  entriesCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number.isRequired,
  plusOne: PropTypes.func.isRequired,
  minusOne: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  masterInventory: PropTypes.instanceOf(MasterInventory).isRequired,
  schoolInventory: PropTypes.instanceOf(SchoolInventory).isRequired,
};
function EntriesListTable(props) {
  const {
    entries,
    entriesCount,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    masterInventory,
    schoolInventory,
    plusOne,
    minusOne,
    onDelete,
    ...other
  } = props;

  const { t, i18n } = useTranslation();

  return (
    <div {...other}>
      <Scrollbar>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("labels.book")}</TableCell>
              {schoolInventory.type === "receipt" && (
                <TableCell>{t("labels.quantity")}</TableCell>
              )}
              {schoolInventory.type === "school" && (
                <TableCell>{t("labels.code")}</TableCell>
              )}
              <TableCell>{t("labels.added")}</TableCell>
              <TableCell>{t("labels.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map((entry) => {
              let book = masterInventory.searchByID(entry.bookID);
              if (!book) {
                book = new Book({ title: "Book not found..." });
              }
              return (
                <TableRow key={entry.id}>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {/* {book.thumbnail ? (
                        <Avatar
                          src={book.thumbnail}
                          sx={{
                            height: 42,
                            width: 42,
                          }}
                        />
                      ) : (
                        <Skeleton
                          animation={false}
                          variant="circular"
                          width={42}
                          height={42}
                        />
                      )} */}
                      <Box sx={{ ml: 1 }}>
                        {book.title}
                        <Typography color="textSecondary" variant="body2">
                          {book.author}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  {schoolInventory.type === "school" && (
                    <TableCell>
                      {formattedCode(entry.letter, entry.number)}
                    </TableCell>
                  )}
                  {schoolInventory.type === "receipt" && (
                    <TableCell>{entry.quantity}</TableCell>
                  )}
                  <TableCell>
                    {entry.updatedAt.toLocaleString(i18n.language)}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      color="error"
                      onClick={() => {
                        onDelete(entry);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    {schoolInventory.type === "receipt" && (
                      <>
                        <IconButton
                          aria-label="+1"
                          color="success"
                          variant="contained"
                          onClick={() => {
                            plusOne(entry);
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                        <IconButton
                          aria-label="-1"
                          color="warning"
                          onClick={() => {
                            // If quantity is 0, delete the entry instead
                            if (entry.quantity - 1 <= 0) {
                              onDelete(entry);
                            } else {
                              minusOne(entry);
                            }
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      <TablePagination
        component="div"
        count={entriesCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
      />
    </div>
  );
}

function formattedCode(letter, number) {
  let code = "?";
  if (letter) code = letter.toUpperCase();
  if (number) {
    if (number < 10) {
      code += "00" + number;
    } else if (number < 100) {
      code += "0" + number;
    } else {
      code += number;
    }
  } else {
    code += "---";
  }

  return code;
}

// Loop through every entry and group them by letter
// The resulting object will be:
// {
//   "A": [
//     {
//       start: 1,
//       end: 10,
//     },
//     {
//       start: 11,
//       end: 13,
//     },
//     {
//       start: 25,
//       end: 100,
//     },
//   ],
//   "B": [
//     {
//       start: 1,
//       end: 100,
//     },
//   ],
// }
function groupEntriesByLetter(entries) {
  // Output variable
  let groupedEntries = {};

  // Get a list of all the letters used in the entires
  let letters = entries.map((entry) => entry.letter.toUpperCase());

  // Sort the letters alphabetically
  letters.sort();

  // Create an entry group
  letters.forEach((letter) => {
    groupedEntries[letter] = [];
  });

  // Sort the entries by number first so we can just expand ranges without having to check
  // if the next entry is the same letter
  const sortedEntries = entries.sort((a, b) => {
    return a.number - b.number;
  });

  // Loop through every entry and either create a new secion or expand the last one
  sortedEntries.forEach((entry) => {
    // Get letter & make it upper case
    let letter = entry.letter.toUpperCase();

    // Parse the number
    entry.number = parseInt(entry.number);

    // If this section is empty, initialize it and continue
    if (groupedEntries[letter].length === 0) {
      groupedEntries[letter].push({
        start: entry.number,
        end: entry.number,
      });
      return;
    }

    // Check the last section of this letter to see if it can be expanded
    let lastSection = groupedEntries[letter][groupedEntries[letter].length - 1];
    if (lastSection.end + 1 === entry.number) {
      // Expand the last section
      lastSection.end = entry.number;
    } else {
      // Create a new section
      groupedEntries[letter].push({
        start: entry.number,
        end: entry.number,
      });
    }
  });

  return groupedEntries;
}
