import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";

import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import LoadingBackdrop from "../../components/LoadingBackdrop.tsx";

import SchoolInventory from "../../state/SchoolInventory";

import {
  addSchoolInventory,
  updateSchoolInventory,
  deleteSchoolInventory,
} from "../../state/firebase/schoolInventories";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { FormGroup } from "@mui/material";

export default function SchoolInventoryMetadataForm(props) {
  const [submitError, setSubmitError] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const { t } = useTranslation();

  // Select fields
  const [type, setType] = React.useState(props.schoolInventory.type);
  const [name, setName] = React.useState(props.schoolInventory.name);

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Clear submit error
    setSubmitError(false);

    const schoolInventory = new SchoolInventory({
      name: name,
      type: type,
      id: props.schoolInventory.id,
      createdAt: props.schoolInventory.createdAt,
      updatedAt: new Date(),
    });

    // If delete mode, just delete the inventory
    if (props.mode === "delete") {
      deleteSchoolInventory(schoolInventory)
        .then(() => {
          setLoading(false);
          enqueueSnackbar(t("alerts.inventory-deleted"), {
            variant: "success",
          });
          props.onComplete();
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
          setSubmitError(t("errors.inventory-delete-failed"));
        });
      return;
    }

    // check for errors
    if (
      schoolInventory.name.length === 0 ||
      schoolInventory.type.length === 0
    ) {
      setSubmitError(t("errors.all-fields-required"));
      return;
    }

    // Show loading screen
    setLoading(true);

    if (props.mode === "create") {
      // @todo Check for an ISBN conflict

      // store result
      addSchoolInventory(schoolInventory)
        .then((ref) => {
          setLoading(false);
          console.log("Inventory added: ", ref.id);

          enqueueSnackbar(t("alerts.inventory-saved"), {
            variant: "success",
            autoHideDuration: 3000,
          });

          props.onComplete();
        })
        .catch((err) => {
          setLoading(false);

          console.error("Error saving inventory: ", err);
          setSubmitError(t("errors.inventory-save-failed"));
        });
    } else if (props.mode === "edit") {
      // store result
      updateSchoolInventory(schoolInventory)
        .then(() => {
          setLoading(false);
          console.log("Inventory updated: ", schoolInventory.id);

          enqueueSnackbar(t("alerts.inventory-saved"), {
            variant: "success",
            autoHideDuration: 3000,
          });

          props.onComplete();
        })
        .catch((err) => {
          setLoading(false);
          // @todo display error
          console.error("Error saving inventory: ", err);
          setSubmitError(t("errors.inventory-save-failed"));
        });
    }
  };

  return (
    <Grid container spacing={3} style={{ paddingTop: 10 }}>
      <Grid item xs={12}>
        <LoadingBackdrop loading={loading} timer={10} />
        <Collapse in={submitError && submitError.length > 0}>
          <Alert severity="error">
            <AlertTitle>{t("errors.error")}</AlertTitle>
            {submitError}
          </Alert>
        </Collapse>
        <form onSubmit={handleSubmit}>
          <FormGroup row>
            {/* Whole grid */}
            {props.mode === "delete" ? (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {t("questions.confirm-delete-inventory")}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    {t("labels.name")}: {props.schoolInventory.name}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    {t("labels.type")}: {props.schoolInventory.type}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={handleSubmit}
                  >
                    {t("buttons.confirm-delete")}
                  </Button>
                </Grid>
              </Grid>
            ) : null}

            {props.mode === "create" || props.mode === "edit" ? (
              <Grid item container spacing={3}>
                <Grid item xs={12} md={5}>
                  <TextField
                    id="name"
                    label={t("labels.school-name")}
                    value={name}
                    name="name"
                    autoFocus={name.length === 0}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    select
                    id="type"
                    label={t("labels.inventory-type")}
                    value={type}
                    name="type"
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                    fullWidth
                    disabled={props.mode === "edit"}
                  >
                    <MenuItem value="school">{t("school-type")}</MenuItem>
                    <MenuItem value="receipt">{t("receipt-type")}</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={3} style={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<SaveIcon />}
                    type="submit"
                  >
                    {t("buttons.save")}
                  </Button>
                </Grid>
              </Grid>
            ) : null}
          </FormGroup>
        </form>
      </Grid>
    </Grid>
  );
}

// Set proptypes for SchoolInventoryMetadataForm
SchoolInventoryMetadataForm.propTypes = {
  schoolInventory: PropTypes.instanceOf(SchoolInventory).isRequired,
  mode: PropTypes.oneOf(["create", "edit", "delete"]).isRequired,
  onComplete: PropTypes.func.isRequired,
};
