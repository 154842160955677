import { useForm, Controller } from "react-hook-form";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { addBook, updateBook } from "../state/firebase/books.ts";
import MultiSelector from "./MultiSelector";
import { MasterInventoryContext } from "../App";
import Book from "../state/Book";
import { CircularProgress } from "@mui/material";
import { useFieldOptions } from "../hooks/useFieldOptions";
import { useSnackbar } from "notistack";
import {
  parseLocationQRCode,
  validateLocationQRCode,
} from "../state/Location.js";

export default function CreateUpdateBookForm({ defaultBook, onComplete }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const masterInventory = React.useContext(MasterInventoryContext);
  const {
    bookLanguages,
    bookTypes,
    bookSources,
    bookLevels,
    bookSpecialCategories,
  } = useFieldOptions();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      author: defaultBook?.author || "",
      isbn: defaultBook?.isbn || "",
      language: defaultBook?.language || "",
      level: defaultBook?.level || "",
      notes: defaultBook?.notes || "",
      quantity: defaultBook?.quantity || "",
      source: defaultBook?.source || [],
      special_category: defaultBook?.special_category || [],
      title: defaultBook?.title || "",
      type: defaultBook?.type || "",
      primary_location: defaultBook?.primary_location || "",
    },
  });

  const onSubmit = async ({ primary_location, ...data }) => {
    try {
      const cleanedLocation = parseLocationQRCode(primary_location);
      const code = cleanedLocation ? cleanedLocation.code() : null;

      if (defaultBook.id) {
        let book = new Book(defaultBook);
        book.author = data.author;
        book.isbn = data.isbn;
        book.language = data.language;
        book.level = data.level;
        book.notes = data.notes;
        book.quantity = data.quantity;
        book.source = data.source;
        book.special_category = data.special_category;
        book.title = data.title;
        book.type = data.type;
        book.primary_location = code;
        await updateBook(book);
      } else {
        const cleanedDefaultBook = Object.entries(defaultBook).reduce(
          (acc, [key, value]) => {
            if (!value) {
              return acc;
            } else {
              return { ...acc, [key]: value };
            }
          }
        );
        await addBook(
          new Book({ ...cleanedDefaultBook, ...data, primary_location: code })
        );
      }
      enqueueSnackbar(t("alerts.book-saved"), {
        variant: "success",
      });
      onComplete();
    } catch (err) {
      console.log("err", err);
      enqueueSnackbar(t("errors.book-save"), {
        variant: "error",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="book-form">
      <Grid container columnSpacing={{ xs: 2, sm: 3 }}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="isbn"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={`${t("labels.isbn")}*`}
                size="small"
                fullWidth
                margin="normal"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="title"
            control={control}
            rules={{ required: "This field is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label={`${t("labels.title")}*`}
                size="small"
                helperText={errors["title"]?.message || ""}
                error={!!errors["title"]}
                fullWidth
                multiline
                margin="normal"
                autoFocus={defaultBook.title.length === 0}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="author"
            control={control}
            render={({ field }) => {
              const { onChange, value, ref } = field;
              return (
                <Autocomplete
                  value={value}
                  onChange={(event, newValue) => {
                    onChange(newValue ? newValue : null);
                  }}
                  freeSolo={true}
                  options={masterInventory.getAuthors()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={ref}
                      size="small"
                      label={t("labels.author")}
                      helperText={errors["author"]?.message || ""}
                      error={!!errors["author"]}
                      fullWidth
                      margin="normal"
                    />
                  )}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="quantity"
            control={control}
            rules={{
              min: {
                value: 0,
                message: "Quantity cannot be a negative number",
              },
              required: "This field is required",
              validate: (value) =>
                !isNaN(value) || "Uh oh! That doesn't look like a number.",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                label={`${t("labels.quantity")}*`}
                size="small"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                helperText={errors["quantity"]?.message || ""}
                error={!!errors["quantity"]}
                fullWidth
                margin="normal"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="language"
            rules={{ required: "This field is required" }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="language"
                select
                label={t("labels.language")}
                size="small"
                helperText={errors["language"]?.message || ""}
                error={!!errors["language"]}
                fullWidth
                margin="normal"
              >
                {bookLanguages.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="type"
            rules={{ required: "This field is required" }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label={t("labels.type")}
                size="small"
                helperText={errors["type"]?.message || ""}
                error={!!errors["type"]}
                fullWidth
                margin="normal"
              >
                {bookTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="level"
            rules={{ required: "This field is required" }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="level"
                select
                label={t("labels.reading-level")}
                size="small"
                helperText={errors["level"]?.message || ""}
                error={!!errors["level"]}
                fullWidth
                margin="normal"
              >
                {bookLevels.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="primary_location"
            control={control}
            rules={{
              validate: (value) => {
                if (!value) return;
                try {
                  validateLocationQRCode(value);
                } catch (err) {
                  return err.message;
                }
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("titles.location")}
                size="small"
                helperText={`${
                  errors["primary_location"]?.message
                    ? `${errors["primary_location"].message}. `
                    : ""
                }${t("helpers.scan-location-code")}.`}
                error={!!errors["primary_location"]}
                fullWidth
                margin="normal"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="source"
            control={control}
            render={({ field }) => (
              <MultiSelector
                {...field}
                label={t("labels.source")}
                error={!!errors["source"]}
                options={bookSources}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="special_category"
            control={control}
            render={({ field }) => (
              <MultiSelector
                {...field}
                label={t("labels.special-category")}
                error={!!errors["special_category"]}
                options={bookSpecialCategories}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="notes"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("labels.notes")}
                size="small"
                helperText={errors["notes"]?.message || ""}
                error={!!errors["notes"]}
                fullWidth
                margin="normal"
                multiline
                minRows={3}
                maxRows={6}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={12}>
          <Button
            disabled={!isValid || isSubmitting}
            variant="contained"
            type="submit"
            fullWidth
            color="success"
            onClick={handleSubmit}
            startIcon={isSubmitting ? null : <SaveIcon />}
          >
            {isSubmitting ? (
              <CircularProgress size={24} />
            ) : (
              t(defaultBook.id ? "buttons.update" : "buttons.save")
            )}
          </Button>
        </Grid>
        <Grid container item sm={12}>
          <Grid item xs={12}>
            {defaultBook.cover && (
              <img
                src={defaultBook.cover}
                alt={t("labels.cover-art")}
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "200px",
                  objectFit: "contain",
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

CreateUpdateBookForm.propTypes = {
  defaultBook: PropTypes.instanceOf(Book).isRequired,
  onComplete: PropTypes.func.isRequired,
};
