import axios from "axios";
import logger from "../utils/logger.ts";

export const searchByIsbn = async (isbn) => {
  return axios
    .get("https://openlibrary.org/api/books", {
      params: {
        bibkeys: "ISBN:" + isbn,
        format: "json",
        jscmd: "data",
      },
      timeout: 8000,
    })
    .then((response) => {
      if (Object.keys(response.data).length > 0) {
        return response.data;
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.log("Error: ", error);
      logger.error("openLibrary-error", error);
      return null;
    });
};
