import DeleteIcon from "@mui/icons-material/Delete";
// import UpIcon from "@mui/icons-material/KeyboardArrowUp";
// import DownIcon from "@mui/icons-material/KeyboardArrowDown";
import DownloadIcon from "@mui/icons-material/Download";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import logger from "../utils/logger.ts";
import { Dashboard } from "./Dashboard";
import LoadingBackdrop from "../components/LoadingBackdrop.tsx";

export default function PrintLabels() {
  const { t } = useTranslation();

  const [labels, setLabels] = React.useState([
    {
      color: "blue",
      letter: "A",
      starting_number: 1,
      ending_number: 10,
      id: randomString(10),
    },
  ]);
  const [startingRow, setStartingRow] = React.useState(1);
  const [startingCol, setStartingCol] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  // get today's date in DD-MM-YYYY format
  const today = new Date();
  const date =
    today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();

  const [filename, setFilename] = React.useState(
    t("words.labels") + "_" + date + ".pdf"
  );

  return (
    <Dashboard title={t("titles.print-labels")}>
      <Grow in={true}>
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={8} lg={9}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LoadingBackdrop loading={loading} timer={30} />
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Collapse in={submitError && submitError.length > 0}>
                    <Alert severity="error">
                      <AlertTitle>{t("errors.error")}</AlertTitle>
                      {submitError ? submitError : ""}
                    </Alert>
                  </Collapse>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="starting_row"
                    label={t("labels.starting-row")}
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={startingRow}
                    onChange={(e) => {
                      // Cap at 20
                      if (e.target.value > 20) {
                        e.target.value = 20;
                      }

                      setStartingRow(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="starting_col"
                    label={t("labels.starting-col")}
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={startingCol}
                    onChange={(e) => {
                      // Cap at 4
                      if (e.target.value > 4) {
                        e.target.value = 4;
                      }

                      setStartingCol(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("labels.color")}</TableCell>
                    <TableCell>{t("labels.letter")}</TableCell>
                    <TableCell>{t("labels.first-number")}</TableCell>
                    <TableCell>{t("labels.last-number")}</TableCell>
                    <TableCell width={200}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {labels.map((row, i) => (
                    <LabelRow
                      key={row.id}
                      id={row.id}
                      data={row}
                      isFirst={i === 0}
                      isLast={i === labels.length - 1}
                      delete={(id) => {
                        // Find the item by ID and delete it
                        let _labels = labels.filter((item) => item.id !== id);
                        // Update the labels state
                        setLabels(_labels);
                      }}
                      onChange={(id, data) => {
                        // console.log("Data for " + id + " changed");
                        // console.log(data);

                        // Find the item by ID and update it
                        let _labels = labels.map((item) => {
                          if (item.id === id) {
                            return data;
                          } else {
                            return item;
                          }
                        });

                        setLabels(_labels);
                      }}
                    />
                  ))}
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                          // Add a new label row
                          let _labels = labels.slice();
                          _labels.push({
                            color: "blue",
                            letter: "A",
                            starting_number: 1,
                            ending_number: 10,
                            id: randomString(10),
                          });
                          // Update the labels state
                          setLabels(_labels);
                        }}
                      >
                        {t("buttons.add-new-label-set")}
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                id="dl_filename"
                label={t("labels.dl-filename")}
                type="text"
                variant="outlined"
                fullWidth
                value={filename}
                onChange={(e) => setFilename(e.target.value)}
              />
              <Button
                color="secondary"
                fullWidth
                onClick={() => {
                  console.log("Printing labels");

                  const settings = {
                    sections: labels,
                    starting_col: startingCol - 1,
                    starting_row: startingRow - 1,
                  };

                  logger.info("label-request", { settings: settings });

                  setLoading(true);
                  setSubmitError(null);

                  // Send API request to generate the PDF
                  axios({
                    method: "POST",
                    url: "https://invenstory-online.appspot.com/labelgen",
                    headers: {
                      "Content-Type": "application/json; charset=utf-8",
                    },
                    data: {
                      settings: settings,
                    },
                    responseType: "blob", // important
                    timeout: 60000,
                  })
                    .then((response) => {
                      console.log("Response: ");
                      const url = window.URL.createObjectURL(
                        new Blob([response.data])
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", filename); //or any other extension
                      document.body.appendChild(link);
                      link.click();

                      enqueueSnackbar(t("downloaded"), { variant: "success" });
                    })
                    .catch((error) => {
                      console.error("Error: ");
                      console.error(error);
                      setSubmitError(JSON.stringify(error));
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                }}
              >
                <DownloadIcon />
                {t("buttons.print-labels")}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Grow>
    </Dashboard>
  );
}

LabelRow.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  delete: PropTypes.func.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
};
function LabelRow(props) {
  const [color, setColor] = React.useState(props.data.color);
  const [letter, setLetter] = React.useState(props.data.letter);
  const [starting_number, setstarting_number] = React.useState(
    props.data.starting_number
  );
  const [ending_number, setending_number] = React.useState(
    props.data.ending_number
  );

  const { t } = useTranslation();

  const handleChange = (e) => {
    let { name, value } = e.target;

    // If field is starting_number or ending_number, convert to number
    if (name === "starting_number" || name === "ending_number") {
      value = Number(value);

      // If not a number, then don't update it
      if (isNaN(value)) {
        return;
      }
    }

    // console.log("Field changed: ", name, value, "\tid: ", props.id);

    // Build new row data
    const newData = { ...props.data };
    newData[name] = value;

    // Update internal state
    if (name === "color") {
      setColor(value);
    } else if (name === "letter") {
      setLetter(value);
    } else if (name === "starting_number") {
      setstarting_number(value);
    } else if (name === "ending_number") {
      setending_number(value);
    }

    // Update state
    props.onChange(props.id, newData);
  };
  return (
    <TableRow key={props.id}>
      <TableCell>
        <TextField
          select
          id="color"
          label={t("labels.color")}
          name="color"
          value={color}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
          fullWidth
        >
          <MenuItem value="blue">{t("colors.blue")}</MenuItem>
          <MenuItem value="yellow">{t("colors.yellow")}</MenuItem>
          <MenuItem value="green">{t("colors.green")}</MenuItem>
          <MenuItem value="red">{t("colors.red")}</MenuItem>
          <MenuItem value="purple">{t("colors.purple")}</MenuItem>
          <MenuItem value="gray">{t("colors.gray")}</MenuItem>
        </TextField>
      </TableCell>
      <TableCell>
        <TextField
          id="letter"
          label={t("labels.letter")}
          name="letter"
          value={letter}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
          fullWidth
        />
      </TableCell>
      <TableCell>
        <TextField
          id="starting_number"
          label={t("labels.first-number")}
          name="starting_number"
          value={starting_number}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
          fullWidth
        />
      </TableCell>
      <TableCell>
        <TextField
          id="ending_number"
          label={t("labels.last-number")}
          name="ending_number"
          value={ending_number}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
          fullWidth
        />
      </TableCell>
      <TableCell>
        <IconButton
          variant="contained"
          color="error"
          size="small"
          onClick={() => {
            props.delete(props.id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

// Function to generate a random string
function randomString(length) {
  // Create a string with the length of the argument
  var result = "";
  // Create a string with the characters to use
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  // Create a variable to store the length of the characters string
  var charactersLength = characters.length;
  // Loop the number of times specified by the length argument
  for (var i = 0; i < length; i++) {
    // Get a random number between 0 and the length of the characters string
    var index = Math.floor(Math.random() * charactersLength);
    // Add the character at the index to the result string
    result += characters.charAt(index);
  }
  // Return the result string
  return result;
}
