import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import PropTypes from "prop-types";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 7 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selectedValue, theme) {
  return {
    fontWeight:
      selectedValue.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const MultiSelector = React.forwardRef(({ label, options, ...props }, ref) => {
  const theme = useTheme();

  return (
    <FormControl margin="normal" fullWidth>
      <InputLabel size="small" id={`${props.name}-label`}>
        {label}
      </InputLabel>
      <Select
        {...props}
        ref={ref}
        label={label}
        labelId={`${props.name}-label`}
        id={props.name}
        multiple
        size="small"
        input={
          <OutlinedInput fullWidth id={`select-${props.name}`} label={label} />
        }
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            style={getStyles(option.value, props.value, theme)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

MultiSelector.displayName = "MultiSelector";

MultiSelector.propTypes = {
  name: PropTypes.string.isRequired, // computer readable name
  value: PropTypes.array.isRequired, // array of selected values
  label: PropTypes.string.isRequired, // Human readable name
  error: PropTypes.bool, // true if error
  onChange: PropTypes.func.isRequired, // handler to register a chip has been selected or deselected
  options: PropTypes.array.isRequired, // What options to show
};

export default MultiSelector;
