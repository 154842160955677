import logger from "./logger.ts";
import type { BookProps } from "../@types/books.ts";

export class MasterInventory {
  books: BookProps[];
  loading: boolean;
  error: Error | null;

  constructor() {
    this.books = [];
    this.loading = true;
    this.error = null;
  }

  setBooks(books: BookProps[]) {
    if (!books) {
      this.books = [];
    }
    this.books = books;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setError(error: Error) {
    this.error = error;
    if (error) {
      logger.error("master-inventory-error", error);
    }
  }

  getAuthors() {
    const authors: string[] = [];

    this.books.forEach((book) => {
      if (!authors.includes(book.author) && book.author) {
        authors.push(book.author);
      }
    });

    // Sort the author list
    authors.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });

    return authors;
  }

  searchByISBN(isbn: string) {
    return this.books.find((book) => book.isbn === isbn);
  }

  searchByID(id: string) {
    return this.books.find((book) => book.id === id);
  }
}
