import { db } from "./firebase";
import {
  collection,
  query,
  addDoc,
  updateDoc,
  deleteDoc,
  where,
  getDocs,
  doc,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { getEnvironment } from "../../utils/environment.ts";

import { collections } from "../../utils/constants.ts";

const schoolInventoryEntriesCollection = () => {
  switch (getEnvironment()) {
    case "localhost":
      return collection(db, collections.localhost.schoolInventoryEntries);
    case "production":
      return collection(db, collections.production.schoolInventoryEntries);
    default:
      throw new Error("Unknown environment: " + getEnvironment());
  }
};

const schoolInventoryEntriesCollectionName = () => {
  switch (getEnvironment()) {
    case "localhost":
      return collections.localhost.schoolInventoryEntries;
    case "production":
      return collections.production.schoolInventoryEntries;
    default:
      throw new Error("Unknown environment: " + getEnvironment());
  }
};

// Build and export a query that gets all entries for a specific listID
export function schoolInventoryEntriesQuery(listID) {
  if (!listID) listID = "";
  return query(
    schoolInventoryEntriesCollection(),
    where("listID", "==", listID)
  );
}

export function useSchoolInventoryEntries(listID) {
  if (!listID) listID = "";
  return useCollection(schoolInventoryEntriesQuery(listID), {});
}

export function useAllSchoolInventoryEntries() {
  return useCollection(schoolInventoryEntriesCollection(), {});
}

// Function to download one school inventory entry, searching by book ID
export const getSchoolInventoryEntryByBookID = async (bookID) => {
  const q = query(
    schoolInventoryEntriesCollection(),
    where("bookID", "==", bookID)
  );
  // Wait for the query to resolve
  const docs = await getDocs(q);

  if (docs.docs.length === 0) {
    return null;
  } else {
    return docs.docs[0];
  }
};

// Function to add a new entry to firebase
// Returns a promise that resolves to the new book
export const addSchoolInventoryEntry = (entry) => {
  const now = new Date();
  const newEntry = {
    ...entry,
    createdAt: now,
    updatedAt: now,
  };

  return addDoc(schoolInventoryEntriesCollection(), newEntry);
};

// Function to update an entry in firebase
// Returns a promise that resolves to the updated book
export const updateSchoolInventoryEntry = (entry) => {
  const now = new Date();
  const updatedEntry = {
    ...entry,
    updatedAt: now,
  };

  const docRef = doc(
    db,
    schoolInventoryEntriesCollectionName() + "/" + entry.id
  );
  return updateDoc(docRef, updatedEntry);
};

// Function to delete an entry from firebase
// Returns a promise that resolves to the deleted book
export const deleteSchoolInventoryEntry = (entry) => {
  const docRef = doc(
    db,
    schoolInventoryEntriesCollectionName() + "/" + entry.id
  );
  return deleteDoc(docRef);
};
