import { Timestamp } from "firebase/firestore";
import Location from "./Location";
export default class Book {
  constructor(obj) {
    // allow no param to be passed in
    if (typeof obj == "undefined") {
      obj = {};
    }

    const now = new Date();

    this.id = "id" in obj ? obj.id : "";

    this.title = "title" in obj ? obj.title : "";
    this.author = "author" in obj ? obj.author : "";
    this.isbn = "isbn" in obj ? obj.isbn : "";
    this.cover = "cover" in obj ? obj.cover : "";
    this.thumbnail = "thumbnail" in obj ? obj.thumbnail : "";

    this.quantity = "quantity" in obj ? parseInt(obj.quantity) : 0;
    this.type = "type" in obj ? obj.type : "";
    this.level = "level" in obj ? obj.level : "";
    this.notes = "notes" in obj ? obj.notes : "";
    this.language = "language" in obj ? obj.language : "";
    this.special_category = "special_category" in obj ? obj.special_category : [];
    this.source = "source" in obj ? obj.source : [];

    this.primary_location = "primary_location" in obj ? obj.primary_location : null;

    this.secondary_locations = "secondary_locations" in obj ? obj.secondary_locations : [];

    this.updatedAt = "updatedAt" in obj ? obj.updatedAt : now;
    this.createdAt = "createdAt" in obj ? obj.createdAt : now;

    // If the dates are firebase dates, convert them to javascript dates
    if (this.updatedAt instanceof Timestamp) {
      this.updatedAt = this.updatedAt.toDate();
    }
    if (this.createdAt instanceof Timestamp) {
      this.createdAt = this.createdAt.toDate();
    }
  }

  data() {
    return {
      id: this.id,

      title: this.title,
      author: this.author,
      isbn: this.isbn,
      cover: this.cover,
      thumbnail: this.thumbnail,

      quantity: this.quantity,
      type: this.type,
      level: this.level,
      notes: this.notes,
      language: this.language,
      special_category: this.special_category,
      source: this.source,

      primary_location: this.primary_location,
      secondary_locations: this.secondary_locations,

      updatedAt: this.updatedAt,
      createdAt: this.createdAt,
    };
  }

  parseOpenLibraryData(data) {
    // @todo try catch?

    console.log("OpenLibrary Response");
    console.log(data);

    // Title is easy
    try {
      this.title = data[Object.keys(data)[0]].title;
    } catch (error) {
      console.log("Title error " + error);
    }

    // @todo append all authors
    try {
      this.author = data[Object.keys(data)[0]].authors[0].name;
    } catch (error) {
      console.log("Author error " + error);
    }

    // @todo Figure out cover images
    try {
      this.cover = data[Object.keys(data)[0]]?.cover?.medium;
    } catch (error) {
      console.log("Cover error " + error);
    }
    try {
      this.thumbnail = data[Object.keys(data)[0]]?.cover?.large;
    } catch (error) {
      console.log("Thumbnail error " + error);
    }
  }

  // Clean the option values. Basically, fuzzy match what might have been intented
  // and use an approved value
  clean() {
    try {
      switch (this.type.toLowerCase()) {
        case "hardcover":
        case "hard cover":
          this.type = "Hardcover";
          break;
        case "softcover":
        case "soft cover":
          this.type = "Softcover";
          break;
        case "cd":
          this.type = "CD";
          break;
        case "board":
          this.type = "Board";
          break;
        default:
          this.type = "UNKNOWN";
      }
    } catch (e) {
      console.log("Type falure w/ ");
      this.log();
      console.log(e);
    }
    try {
      switch (this.special_category.toLowerCase()) {
        case "rare":
          this.special_category = "Rare";
          break;
        case "high school":
        case "highschool":
          this.special_category = "High School";
          break;
        case "buy more":
          this.special_category = "Buy More";
          break;
        case "teacher resource":
          this.special_category = "Teacher Resource";
          break;
        default:
          this.type = "UNKNOWN";
      }
    } catch (e) {
      console.log("Category falure w/ ");
      this.log();
      console.log(e);
    }
  }

  log() {
    console.log("Book Record:");
    console.log("ID: " + this.id);
    console.log("Title: " + this.title);
    console.log("ISBN: " + this.isbn);
    // console.log("Author: "+this.author)
    // console.log("Cover: "+this.cover)
    // console.log("Thumb: "+this.thumbnail)
  }

  getPrimaryLocation() {
    try {
      return new Location(this.primary_location);
    } catch (e) {
      return new Location();
    }
  }
}
