import { useTranslation } from "react-i18next";

export const useFieldOptions = () => {
  const { t } = useTranslation();

  const bookLanguages = [
    {
      value: "english",
      label: t("english"),
    },
    {
      value: "spanish",
      label: t("spanish"),
    },
    {
      value: "bilingual",
      label: t("bilingual"),
    },
    {
      value: "wordless",
      label: t("wordless"),
    },
  ];

  // board, hardcover, softcover, cd, teacher-manual, other
  const bookTypes = [
    {
      value: "board",
      label: t("board"),
    },
    {
      value: "hardcover",
      label: t("hardcover"),
    },
    {
      value: "softcover",
      label: t("softcover"),
    },
    {
      value: "cd",
      label: t("cd"),
    },
    {
      value: "teacher-manual",
      label: t("teacher-manual"),
    },
    {
      value: "other",
      label: t("other"),
    },
  ];

  // sources: scholastic online, amazon, warehouse sale, first book, book outlet, liber e books, other
  const bookSources = [
    {
      value: "scholastic online",
      label: "Scholastic Online",
    },
    {
      value: "amazon",
      label: "Amazon",
    },
    {
      value: "warehouse sale",
      label: "Warehouse Sale",
    },
    {
      value: "first book",
      label: "First Book",
    },
    {
      value: "book outlet",
      label: "Book Outlet",
    },
    {
      value: "liber e books",
      label: "Liber e Books",
    },
    {
      value: "books 4 school",
      label: "Books 4 School",
    },
    {
      value: "other",
      label: t("other"),
    },
  ];

  // Reading levels: advanced, intermediate, beginner, other
  const bookLevels = [
    {
      value: "advanced",
      label: t("advanced"),
    },
    {
      value: "intermediate",
      label: t("intermediate"),
    },
    {
      value: "beginner",
      label: t("beginner"),
    },
    {
      value: "other",
      label: t("other"),
    },
  ];

  // Special categories: rare, high school, buy more, teacher resource
  const bookSpecialCategories = [
    {
      value: "rare",
      label: t("rare"),
    },
    {
      value: "high school",
      label: t("high-school"),
    },
    {
      value: "buy more",
      label: t("buy-more"),
    },
    {
      value: "teacher resource",
      label: t("teacher-resource"),
    },
  ];

  return {
    bookLanguages,
    bookTypes,
    bookSources,
    bookLevels,
    bookSpecialCategories,
  };
};
