import { datadogLogs } from "@datadog/browser-logs";

class Logger {
  // Wrapper function for each log level (debug, info, warn, error)
  debug(event: string, data: Error) {
    try {
      datadogLogs.logger.debug(event, data);
    } catch (err) {
      console.error("datadog error", err);
    }
  }

  info(event: string, data: Error) {
    try {
      datadogLogs.logger.info(event, data);
    } catch (err) {
      console.error("datadog error", err);
    }
  }

  error(event: string, data: Error) {
    try {
      datadogLogs.logger.error(event, data);
    } catch (err) {
      console.error("datadog error", err);
    }
  }

  pageLoad() {
    try {
      datadogLogs.logger.info("page-load");
    } catch (err) {
      console.error("datadog error", err);
    }
  }
}

const logger = new Logger();
export default logger;
