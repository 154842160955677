import React, { Suspense } from "react";
import Box from "@mui/material/Box";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { datadogLogs } from "@datadog/browser-logs";
import { SnackbarProvider } from "notistack";
import LogRocket from "logrocket";

import App from "./App.jsx";

LogRocket.init("nljgup/invenstory", {});

datadogLogs.init({
  clientToken: "pub235d73f04622108b9f6a2e33587a23f4",
  site: "datadoghq.com",
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: "invenstory-react",
  // env: "",
  // version: ""
});

// @todo see if there's an easy way to timeout the suspense
let el = document.getElementById("root");

if (!el) {
  el = document.createElement("div");
  el.setAttribute("id", "root");
  document.body.insertBefore(el, document.body.childNodes[0]);
}

const root = ReactDOM.createRoot(el);
root.render(
  <Suspense fallback={<Loading />}>
    <BrowserRouter>
      <React.StrictMode>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <App />
        </SnackbarProvider>
      </React.StrictMode>
    </BrowserRouter>
  </Suspense>
);

// Center the loading indicator in the middle of the screen
function Loading() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "60vh",
      }}
    >
      {/* <CircularProgress size={200} thickness={2} /> */}
      <div className="bg applicationLoader shadow"></div>
      <div className="bookshelf_wrapper applicationLoader">
        <ul className="books_list">
          <li className="book_item first"></li>
          <li className="book_item second"></li>
          <li className="book_item third"></li>
          <li className="book_item fourth"></li>
          <li className="book_item fifth"></li>
          <li className="book_item sixth"></li>
        </ul>
        <div className="shelf"></div>
        <div className="loading">Loading...</div>
      </div>
    </Box>
  );
}
