import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import Book from "../state/Book.js";
import { MasterInventoryContext } from "../App.jsx";
import { useSnackbar } from "notistack";
import { searchByIsbn } from "../api/openlibrary.js";
import CircularProgress from "@mui/material/CircularProgress";
import type { BookProps } from "../@types/books.js";

export const IsbnBookSearch = ({
  setBook,
}: {
  setBook: (book: BookProps) => void;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const masterInventory = React.useContext(MasterInventoryContext);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      isbn: "",
    },
  });

  const onSubmit = async ({ isbn }: { isbn: string }) => {
    // Remove any non-numeric characters from the ISBN
    const trimmedIsbn = isbn.replace(/\D/g, "");
    const existingBook = masterInventory.searchByISBN(trimmedIsbn);
    if (existingBook) {
      enqueueSnackbar(
        t("errors.isbn-in-use", {
          isbn: trimmedIsbn,
          title: existingBook.title,
        }),
        {
          variant: "warning",
          autoHideDuration: 10000,
        }
      );
      setBook(existingBook);
    } else {
      const foundBook = await searchByIsbn(trimmedIsbn);
      if (foundBook) {
        const book = new Book({ isbn: trimmedIsbn });
        book.parseOpenLibraryData(foundBook);
        setBook(book);
      } else {
        enqueueSnackbar(t("errors.isbn-not-found-manually-enter"), {
          variant: "warning",
        });
        setBook(new Book({ isbn: trimmedIsbn }));
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Controller
            name="isbn"
            control={control}
            rules={{ required: "Please provide an ISBN number" }}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.isbn}
                label={t("labels.isbn")}
                size="large"
                fullWidth
                multiline
                margin="normal"
                autoFocus={true}
                helperText={t("helpers.scan-enter-isbn")}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            startIcon={isSubmitting ? null : <SearchIcon />}
            disabled={!isValid || isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress color="primary" size={24} />
            ) : (
              t("buttons.search")
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
