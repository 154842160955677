import React from "react";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import TableSortLabel from "@mui/material/TableSortLabel";
import FilterListIcon from "@mui/icons-material/FilterList";

export const TableHeaderCell = ({
  children,
  filterOptions,
  updateFilter,
  sortEnabled,
  sortActive,
  sortDirection,
  sortOnClick,
  multiFilter,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [checked, setChecked] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);

    if (!multiFilter) {
      if (currentIndex === -1) {
        setChecked([value]);
        updateFilter([value]);
      } else {
        setChecked([]);
        updateFilter([]);
      }
    } else {
      const newChecked = [...checked];
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
      updateFilter(newChecked);
    }
  };

  const content = sortEnabled ? (
    <TableSortLabel
      active={sortActive}
      direction={sortDirection}
      onClick={sortOnClick}
    >
      {children}
    </TableSortLabel>
  ) : (
    children
  );

  const ListFilterElement = multiFilter ? Checkbox : Radio;

  return (
    <TableCell {...rest}>
      {content}
      {filterOptions?.length && (
        <>
          <IconButton aria-label="filter" size="small" onClick={handleClick}>
            <FilterListIcon
              fontSize="inherit"
              color={checked?.length ? "success" : undefined}
            />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              {filterOptions.map(({ label, value }) => {
                const labelId = `checkbox-list-label-${value}`;

                return (
                  <ListItem key={value} disablePadding>
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(value)}
                      dense
                      selected={checked.indexOf(value) !== -1}
                    >
                      <ListItemIcon>
                        <ListFilterElement
                          edge="start"
                          checked={checked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={`${label}`} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Popover>
        </>
      )}
    </TableCell>
  );
};
