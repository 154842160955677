import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

import propTypes from "prop-types";

TimedProgressBar.propTypes = {
  duration: propTypes.number.isRequired,
  type: propTypes.oneOf(["linear", "circular"]),
};

export default function TimedProgressBar(props) {
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const timer = React.useRef();

  const delayMs = 100;

  // Calculate the total number of steps in the progress bar if the duration is set and we update twice per second
  const totalSteps = Math.floor(props.duration / (delayMs / 1000.0));

  // Calculate the step size based on the # of setps
  const stepSize = 100 / totalSteps;

  const progressRef = React.useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress >= 100) {
        clearInterval(timer.current);
      } else {
        const diff2 = Math.random() * 10;

        // Apply a random modifier of -/+ 2 to the progress
        let diff = Math.random() * 4;
        diff = diff - 2;
        diff += stepSize;

        const newProgress = progress + diff;
        if (newProgress < 0) {
          setProgress(0);
          setBuffer(10);
        } else if (newProgress > 100) {
          setProgress(100);
          setBuffer(100);
        } else {
          setProgress(newProgress);
          setBuffer(progress + diff + diff2);
        }
      }
    };
  });

  React.useEffect(() => {
    timer.current = setInterval(() => {
      progressRef.current();
    }, delayMs);

    return () => {
      clearInterval(timer.current);
    };
  }, []);

  if (props.type === "linear") {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress
          variant="buffer"
          value={progress}
          valueBuffer={buffer}
        />
      </Box>
    );
  } else {
    return (
      <Box sx={{ width: "100%" }}>
        <CircularProgress variant="determinate" value={progress} size={200} />
      </Box>
    );
  }
}
