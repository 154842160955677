import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grow from "@mui/material/Grow";
import CancelIcon from "@mui/icons-material/Cancel";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import Book from "../state/Book";
import CreateUpdateBookForm from "./CreateUpdateBookForm";

BookEditDialog.propTypes = {
  book: PropTypes.instanceOf(Book).isRequired,
  isOpen: PropTypes.bool.isRequired, // the outside world controls if we're open or not
  onClose: PropTypes.func.isRequired, // When we're done (cancel button, save is complete, ...) let the world know so they can close us
};

export default function BookEditDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { t } = useTranslation();

  return (
    <Dialog
      open={props.isOpen}
      TransitionComponent={Transition}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      fullWidth
      maxWidth={"lg"}
      fullScreen={fullScreen}
    >
      <DialogTitle id="alert-dialog-title">{t("titles.edit-book")}</DialogTitle>
      <DialogContent>
        <CreateUpdateBookForm defaultBook={props.book} onComplete={props.onClose} />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} startIcon={<CancelIcon />}>
          {t("buttons.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      timeout={1000}
      ref={ref}
      {...props}
      {...{ timeout: 600 }} // why do I have to use this weird syntax?
    />
  );
});
