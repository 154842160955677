import AddBoxIcon from "@mui/icons-material/AddBox";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FormatShapesIcon from "@mui/icons-material/FormatShapes";
import LayersClearIcon from "@mui/icons-material/LayersClear";
// import PeopleIcon from "@mui/icons-material/People";
import PrintIcon from "@mui/icons-material/Print";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { useTheme } from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useResolvedPath, useMatch } from "react-router-dom";

import { useKonamiCode } from "../utils/konamiCode.ts";

export default function MainMenu() {
  const { t } = useTranslation();

  // Check if admin flag is set
  const showAdminSettings = useKonamiCode();

  // Dialog settings
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      {/* <List>
        <ActiveLink
          to="/"
          icon={<PeopleIcon />}
          label={t("buttons.dashboard")}
          {...linkProps}
        />
      </List>
      <Divider /> */}
      <List>
        <ListSubheader inset>{t("titles.inventories")}</ListSubheader>
        <ActiveLink
          to="/inventories"
          label={t("buttons.open")}
          icon={<DashboardIcon />}
        />
      </List>
      <Divider />
      <List>
        <ListSubheader inset>{t("titles.tools")}</ListSubheader>
        <ActiveLink
          to="/printLabels"
          label={t("buttons.print-labels")}
          icon={<PrintIcon />}
        />
      </List>
      <Divider />
      <List>
        <ListSubheader inset>{t("titles.master-inventory")}</ListSubheader>
        <ActiveLink
          to="/books"
          label={t("buttons.view-all")}
          icon={<LibraryBooksIcon />}
        />
        <ActiveLink
          to="/books/addByISBN"
          label={t("buttons.add-by-isbn")}
          icon={<AddBoxIcon />}
        />
        <ActiveLink
          to="/books/checkInOut"
          label={t("buttons.check-in-out")}
          icon={<QrCode2Icon />}
        />
        <ActiveLink
          to="/books/locations"
          label={t("buttons.locations")}
          icon={<GpsFixedIcon />}
        />
      </List>
      {showAdminSettings && (
        <>
          <Divider />
          <List>
            <ListSubheader inset>Admin</ListSubheader>
            <ActiveLink
              to="/duplicateBooks"
              label="Duplicate Books"
              icon={<ContentCopyIcon />}
            />
            <ActiveLink
              to="/coverArt"
              label="Cover Art"
              icon={<FormatShapesIcon />}
            />
            <ActiveLink
              to="/zeroOut"
              label="Zero Out Inventory"
              icon={<LayersClearIcon />}
            />
          </List>
        </>
      )}
    </div>
  );
}

function ActiveLink({ to, ...props }) {
  const theme = useTheme();
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  const isActive = match ? true : false;

  return (
    <div>
      <Link
        style={{
          fontWeight: isActive ? "bold" : "",
          textDecoration: "none",
          color: theme.palette.grey[900],
        }}
        to={to}
        {...props}
      >
        <ListItem button selected={isActive}>
          <ListItemIcon>{props.icon}</ListItemIcon>
          <ListItemText primary={props.label} />
        </ListItem>
      </Link>
    </div>
  );
}
