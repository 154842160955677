export default class Location {
  constructor(obj) {
    // allow no param to be passed in
    if (typeof obj == "undefined") {
      obj = {
        type: "nowhere",
      };
    }

    // If we were passed a string, parse it into a Location
    if (typeof obj == "string") {
      try {
        obj = parseLocationQRCode(obj);
      } catch (e) {
        console.error("Couldn't parse given location code");
        console.error(e);
      }
    }

    // If obj is not an object, make it one
    if (typeof obj !== "object") {
      obj = {};
    }

    this.id = "id" in obj ? obj.id : "";

    // Require type to be "primary" or "storage"
    this.type = "type" in obj ? obj.type : "";
    if (this.type !== "primary" && this.type !== "storage" && this.type !== "nowhere") {
      throw new Error("Location type must be 'primary' or 'storage (or nowhere)': " + this.type);
    }

    // Cast category to uppercase
    this.category = "category" in obj ? obj.category.toUpperCase() : "";
    this.number = "number" in obj ? obj.number : 0;
  }

  code() {
    return "loc:" + this.type + ":" + this.category + ":" + this.number;
  }

  name() {
    switch (this.type) {
      case "nowhere":
        return "n/a";
      case "primary":
        // Shelves in the form of "<letter> - <number>"
        // Examples: P - 1, P - 2, P - 3, etc.
        return this.category + "-" + this.number;
      case "storage":
        // Storage locations in the form of "<animal> - <number>"
        switch (this.category) {
          case "A":
            return "🐄 - " + this.number;
          case "B":
            return "🐑 - " + this.number;
          case "C":
            return "🐓 - " + this.number;
          case "D":
            return "🐖 - " + this.number;
          case "E":
            return "🐇 - " + this.number;
          case "F":
            return "🐈 - " + this.number;
          case "G":
            return "🦜 - " + this.number;
          default:
            return this.category + " - " + this.number;
        }
      default:
        return "";
    }
  }
}

export const validateLocationQRCode = (qrCode) => {
  // QR Code is in the format: "loc:<type>:<category>:<number>"
  // Example: "loc:primary:P:1"

  // Split the QR code into its parts
  const parts = qrCode.split(":");
  if (parts.length !== 4) {
    // manual entry
    const manualParts = qrCode.split("-");
    if (manualParts.length !== 2 || manualParts[0] === "" || manualParts[1] === "") {
      throw new Error("Invalid location format. Format should be <category>-<number>");
    }
    return;
  }

  // Check that the QR code has the correct format
  if (parts.length !== 4) {
    throw new Error("Invalid QR code format. Format should be loc:<type>:<category>:<number>");
  }

  // Check that the QR code has the correct prefix
  if (parts[0] !== "loc") {
    throw new Error("Invalid QR code prefix");
  }

  // Check that the QR code has the correct type
  if (parts[1] !== "primary" && parts[1] !== "storage") {
    throw new Error("Invalid QR code type");
  }
};

export const parseLocationQRCode = (qrCode) => {
  if (!qrCode) return;
  validateLocationQRCode(qrCode);

  // Split the QR code into its parts
  const parts = qrCode.split(":");

  if (parts.length === 4) {
    // Make a fake ID from the information
    const id = parts[1] + "-" + parts[2] + "-" + parts[3];

    // Return the location
    return new Location({
      type: parts[1],
      category: parts[2].toUpperCase(),
      number: parseInt(parts[3]),
      id: id,
    });
  } else {
    const manualParts = qrCode.split("-");
    const id = "primary-" + manualParts[0] + "-" + manualParts[1];
    return new Location({
      type: "primary",
      category: manualParts[0].toUpperCase(),
      number: parseInt(manualParts[1]),
      id: id,
    });
    // return `loc:primary:${manualParts[0]}:${manualParts[1]}`;
  }
};
