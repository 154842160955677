import * as React from "react";

import Backdrop from "@mui/material/Backdrop";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import TimedProgressBar from "./TimedProgressBar";

import { useTranslation } from "react-i18next";

export default function LoadingBackdrop({
  loading,
  timer = 10,
}: {
  loading: boolean;
  timer?: number;
}) {
  const { t } = useTranslation();

  // @todo - make this switch to an indeterminate progress bar instead
  // Need to have the progress bar be gated by loading so the timer resets each time the module is re "opened"

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <Grid container spacing={10}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          {loading ? <TimedProgressBar duration={timer} type="linear" /> : null}
          <Typography variant="h6" color="inherit">
            {t("loading")}...
          </Typography>
        </Grid>
      </Grid>
    </Backdrop>
  );
}
