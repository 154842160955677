import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useTranslation } from "react-i18next";

import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import SaveIcon from "@mui/icons-material/Save";

import { parseLocationQRCode } from "../../state/Location";

function ScanForm({ onDataReady }) {
  const { t } = useTranslation();

  const [isbn, setIsbn] = React.useState("");
  const [code, setCode] = React.useState("");

  const [submitError, setSubmitError] = React.useState(null);

  const isbnRef = React.useRef();
  const codeRef = React.useRef();

  // function for focusing to one of the text fields
  const focus = (ref) => {
    ref.current.focus();
  };

  const handleClear = () => {
    setIsbn("");
    setCode("");

    focus(isbnRef);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let _isbn = isbn;
    let _code = code;

    // Submit might have happened by the scanner pressing "enter" after entering the data
    // If so, check if either field is empty. If it's empty, then focus on the other field
    if (_isbn.length === 0) {
      focus(isbnRef);
      return;
    } else if (_code.length === 0) {
      // Only care about the code field if we're in school mode
      focus(codeRef);
      return;
    }

    // accept either isbn or code in either field.
    // Ib ISBN has the deliniator characters, then switch it
    if (_isbn.includes(":") || _isbn.includes("loc")) {
      console.log(
        "Flip flop! Codes were scanned in reverse but that's OK  ✈️👻☮️ "
      );
      const oldIsbn = _isbn;
      const oldCode = _code;

      _isbn = oldCode;
      _code = oldIsbn;
    }

    // Require ISBN
    if (_isbn.length === 0) {
      handleClear();
      setSubmitError(t("errors.isbn-required"));
      return;
    }

    // Parse code
    try {
      const location = parseLocationQRCode(_code);
      onDataReady({
        location: location,
        isbn: _isbn,
      });
    } catch (e) {
      setSubmitError(t("errors.location-code-invalid"));
    }
    handleClear();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label={t("labels.isbn")}
            value={isbn}
            onChange={(e) => setIsbn(e.target.value)}
            fullWidth
            inputRef={isbnRef}
            autoFocus={true}
            name="isbn"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t("labels.code")}
            value={code}
            onChange={(e) => setCode(e.target.value)}
            fullWidth
            inputRef={codeRef}
            name="code"
          />
        </Grid>
        <Grid item xs={12}>
          <Collapse in={submitError && submitError.length > 0}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {submitError ? submitError : ""}
            </Alert>
          </Collapse>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="success"
            type="submit"
            startIcon={<SaveIcon />}
          >
            {t("buttons.save")}
          </Button>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleClear();

              setSubmitError(null);
            }}
            startIcon={<CancelIcon />}
          >
            {t("buttons.clear")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default ScanForm;
